@import '../../../common/assets/styles/variables.scss';

.notification-container{
  position: fixed;
  top: 80px;
  right: 16px;
  z-index: 100;
  background-color: white;
  width: 30%;
  min-width: none;
  width: 600px;
  height: calc(100% - 90px);
  border-radius: 8px;
  box-shadow: 0 4px 32px rgba(0, 0, 0, 0.12);
  overflow-y: scroll;


  .notification-header{
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e1e4ea;

    
    .notification-title{
      font-weight: 600;
      font-size: 20px;
      color: $titleColor;
    }

    .important-notification{
      display: flex;
      gap: 8px;
      align-items: center;

      .imp-title{
        font-weight: 500;
        color: $snackbarTextColor;
      }

      .imp-count{
        font-weight: 500;
        color: white;
        background-color: $highlightTextColor;
        border-radius: 100%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
      
      .k-switch-on .k-switch-track{
        background-color: $primaryColor;
        outline: none;
        border-color: transparent;
      }
      .k-switch-off .k-switch-track{
        background-color: $tabItemBorderColor;
        outline: none;
        border-color: $tabItemBorderColor;
      }

   .k-switch-track{
        width: 44px;
      }
    
      .notification-switch{
        width: 44px;

      
    
        .k-switch-label-on, .k-switch-label-off{
          display: none;
        }

        .k-switch-off{
          background-color: $snackbarTextColor;
        }
       

        .k-switch-thumb{
          background-color: white;
        }

      }

      .cross-icon{
        margin-left: 8px;
        cursor: pointer;

      }

    

      
    }

  }

  .notification-body{
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
  
    .divider{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
    
    .divider::before, .divider::after{
      width: 70px;
      content: '';
      border-bottom: 1px solid #B5B5B5;
    }

    .divider span{
      margin: 0 8px;
      color: #153E6E;
      font-weight: 600;
      font-size: 12px;
    }

    .notification-divider{
      border: 1px dashed #B5B5B5;
      margin: 24px 0px;

    
    }

    .notification-loader{
      display: flex;
      justify-content: center;
      align-items: center;

      .k-loader-primary{
        color: $primaryColor;
      }
    }

  }
}