@import '../../common/assets/styles/variables.scss';

.left-container {
  padding: 28px 24px;
  border-radius: 8px;
  box-shadow: 8px 16px 52px -28px rgba(57, 125, 171, 0.10);
  width: 40%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

  .center-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 75vh;
    overflow-y: scroll;

  .title-text {
    font-weight: 500;
    color: #3A3A3A;
    font-weight: 600;
    font-size: 20px;
  }

  .center-header {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 14px;

    .header-content{
      display: flex;
      flex-direction: column;
      gap: 14px;
      width: 50%;

      .title-text {
        @extend .title-text;
        font-weight: 500; 
        font-size: 16px;
    
        &::after {
          content: "*";
          margin: 0 2px;
          color: red;
        }
    }
    

      .center-dropdown {
        width: 100%;
        border: 1px solid #e4e7eb;
  
        &:focus-within {
          background-color: white;
          border: 1px solid #e4e7eb;
        }
  
        &:hover {
          border: 1px solid #e4e7eb;
        }
  
        .k-button {
          background-color: white;
        }
  
      }

    }

    & .dropdown-list {
      width: 50%;
    }

    & .k-button {
      color: $primaryBtnBgColor;
      background-color: #FCE7D6;
      border: none;
      padding: 8px 16px;

      .k-button-text {
        font-weight: 600;
      }
    }
  }
    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      height: 500px;

      .form-body{

       display: flex;
       flex-direction: column;
       gap: 24px;

       .form-content{
        display: flex;
        flex-direction: column;
        gap: 14px;
      

        .file-label {
          @extend .title-text;
          font-weight: 500; 
          font-size: 16px;

          &::after {
            content: "*";
            margin: 0 2px;
            color: red;
          }
          
        }

        .comment-label{
          @extend .title-text;
          font-weight: 500; 
          font-size: 16px;
          
        }


      .k-upload{
        border-radius: 4px;
      }

      .k-upload-dropzone{
        background-color:white;
        padding: 5px 10px;
        border-radius: 4px;

        .k-upload-button-wrap>button{
          min-width: 0;
        }

      }

      .upload-file{
        padding: 6px 12px;
        border:1px solid #e4e7eb;
        border-radius: 0.375rem;
        display: flex;
        justify-content: space-between;

        .text-container{
          width: 50%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .placeholder-text{
          color: #B0B0B0;
        }

      }

    }


    }

      .k-button{
       background-color: transparent;
       color: $primaryBtnBgColor;
       border: none;
       padding: 0;

       &:focus{
        box-shadow: none;
       }
       
        
      }
      .k-button-text>span{
        font-weight: 500;

      }

      .k-form-field{
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 0;

      }

      .k-form-buttons{
        .k-button{
          padding: 8px 16px;
          color: white ;
          background-color: $primaryBtnBgColor;

          .k-button-text {
            font-weight: 600;
          }
        }
      }


    }
    
  }
}

.center-dropdown-popup {

  .k-list{
    border-radius: 0.375rem;
  }

  .k-list-item {
    box-shadow: none;
  }
  
  
}