.input {
  display: none;
}

.dragDropContainer {
  margin-top: 1rem;
  border: 1px dashed #c7d2de;
  border-radius: 8px;
  width: 60%;
  min-height: 30vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
}

.uploadIcon {
  width: 3rem;
  height: auto;
}

.container {
  width: 60%;
}

.fileInfoContainer {
  width: 60%;
  padding: 1rem;
  background-color: #f2f5f7;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.fileInfoContainer> :global(span) {
  color: red;
}

.fileInfoContainer> :global(*) {
  flex: none;
}

.container> :global(button) {
  max-width: 140px;
}

.fileNameContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 100;
}

.fileNameContainer > :global(span::before){
  font-size: 2rem !important;
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(143, 234, 143);
  height: 100%;
  border-radius: 6px;
  transition: width 0.5s ease;
}

.dropzoneHover {
  background-color: #BEE3F8 !important;
}