@import "../../common/assets/styles/variables.scss";

.call-log-action{

  .k-button{
    border: none;
    background-color: transparent;
    box-shadow: none;

    .k-button-text{
      display: flex;
      align-items: center;
      gap: 5px;  
    }
    
    .call-text{
      font-size: 14px;
      font-weight: 500;
      color: $primaryColor;
    }
  }


}

.recording-container {
  display: flex;
  flex-direction: column;
  gap: 6px; 
  border-radius: 5px;

  .play-button {
    align-self: center;
    cursor: pointer;
  }
  
}


