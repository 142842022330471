.ra_activity{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
}

.ra_activity__status{
  font-size: 14px;
  font-weight: 500;
}.quote_container_data--label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #9EA7BB;
  margin-bottom: 4px !important;
}

.head_content{
  display: flex;
  align-items: center;
  gap: 10px;
}

.head_content_no_file,.head_content_file{
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin-left: 0px;
  margin: 0 !important;
}

.head_content_file{
  text-decoration: underline;
  cursor: pointer;
}

.head_content_inputLabel{
  margin: 0 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: #0059A3;
  display: flex;
  gap: 2px;
  align-items: center;
}

.head_content_inputLabel :global(.k-i-edit::before){
  font-size: 12px;
}
.notificationIcon{
  border: 1px solid #fff;
  padding: 15px;
  border-radius: 40px;
  cursor: pointer;
}