.price_modal {
  z-index: 1000 !important;
  padding: 10px;
}

.head {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: fit-content;
  margin: 10px 0px;
  margin-left: 12px;
  min-height: 200px;
}

.loader{
  min-height: 200px;
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_userAction {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.head_userAction--heading {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #465166;
}

.head_userAction--subheading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #7E8594;
  margin-bottom: 20px;
}

.head_userActionInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 20px;
}

.head_userActionInput--label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #465166;
}

.head_userActionInput--input {
  width: 250px;
  height: 42px;
  padding: 6px;
  gap: 0px;
  border-radius: 0px;
  border: 1px solid #E6EEF6;
  color: #0059A3;
}

.head_userActionInput--negativebutton {
  width: 50px;
  height: 43px;
  margin: 0;
  border: 0;
  border-radius: 0px;
  background: #ECF4FF;
  border: 1px solid #E6EEF6;
  color: #0059A3;
  border-radius: 4px 0px 0px 4px;
}

.head_userActionInput--positivebutton {
  width: 50px;
  height: 43px;
  margin: 0;
  border: 0;
  border-radius: 0px;
  background: #ECF4FF;
  border: 1px solid #E6EEF6;
  color: #0059A3;
  border-radius: 0px 4px 4px 0px;
}


.head_userButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid #E6EEF6
}

.head_userButtons--submit {
  width: 170.5px;
  height: 42px;
  padding: 9px 13px 9px 13px;
  gap: 2px;
  border-radius: 4px;
  border: 1px solid #005399;
  background: #0059A3;
  color: white;
  margin: 0;
}

.head_userButtons--cancel{
  width: 170.5px;
  height: 42px;
  padding: 9px 13px 9px 13px;
  gap: 2px;
  border-radius: 4px;
  border: 1px solid #005399;
  background: white;
  color: #0059A3;
  margin: 0;
}


input[type="range"] {
  width: 350px;
}

input[type='range']::-webkit-slider-thumb {
  width: 16px;
  -webkit-appearance: none;
  height: 16px;
  background: white;
  border-radius: 50%;
  border: 1px solid #0059A3
}

.main-box {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#stepsall {
  border-radius: 20px;
  height: 2px;
  outline: none;
  -webkit-appearance: none;
  padding: 3px;
  background: linear-gradient(90deg, #00BA67 0%, #00BA67 36.5%, #F6A70A 66.17%, #EB5757 85%, #BC4545 98.67%);
}

.steps_labels{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.steps_labels--min{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color:#6A7384;
}

.steps_labels--max{
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color:#6A7384;
}
