.bankAccLabel {
	font-size: 12px;
	font-weight: 400;
	line-height: 16.5px;
	color: #536179;
	margin-bottom: 8px !important;
}

.bankAccValue {
	font-size: 14px;
	font-weight: 500;
	line-height: 18px;
	color: #1f242d;
	margin-bottom: 0 !important;
}

.submitCta {
	background-color: #ff7d1f;
	color: #ffffff;
	height: 45px;
	min-width: 150px;
	border-radius: 12px;
}

.submitCta span {
	font-weight: 700;
	font-size: 16px;
}

.submitCta:hover {
	background-color: #ff7d1f;
	opacity: 0.6;
}

.popCta {
	background-color: #ff7d1f;
	color: #ffffff;
	height: 45px;
	min-width: 150px;
	border-radius: 12px;
}

.popCta:hover {
	background-color: #ff7d1f;
	opacity: 0.6;
}

.popCta span {
	font-weight: 700;
	font-size: 16px;
}

.cancelCta {
	background-color: #ffffff;
	color: #ff7d1f;
	height: 45px;
	border: 1px solid #ff7d1f;
	min-width: 150px;
	border-radius: 12px;
}

.cancelCta:hover {
	background-color: #ffffff;
	border: 1px solid #ff7d1f;
	opacity: 0.6;
}

.cancelCta span {
	font-weight: 700;
	font-size: 16px;
}

.bankInfoText {
	border-radius: 8px;
	background-color: #f0f2f5;
	color: #536179;
	text-align: left;
	align-items: center;
	padding: 4px 10px;
	margin-right: 10px;
	height: 50px;
	display: flex;
}

.bankFullVerifiedText {
	display: flex;
	border-radius: 8px;
	background-color: #f0fff9;
	color: #00ba67;
	text-align: left;
	align-items: center;
	padding: 4px 10px;
	margin-right: 10px;
	border: 1px solid #00ba67;
	height: 50px;
	font-weight: 500;
}

.mismatchReason {
	display: flex;
	border-radius: 8px;
	background-color: #fef0c7;
	color: #b54708;
	text-align: left;
	align-items: center;
	padding: 4px 10px;
	margin-right: 10px;
	border: 1px solid #ef6e0b;
	height: 50px;
	font-weight: 500;
}

.addPayment {
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.bankHeader {
	font-weight: 600;
	font-size: 16px;
}

.bankThirdPartyText {
	border-radius: 8px;
	background-color: #d1e9ff;
	color: #1f242d;
	text-align: left;
	align-items: center;
	padding: 12px 10px;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

.uploadedImage {
	width: 240px;
	height: 160px;
	margin-bottom: 10px;
}

.uploadedImage img {
	max-width: 100%;
	max-height: 100%;
	border-radius: 10px;
	cursor: pointer;
}

.uploadedImage object {
	max-width: 100%;
	max-height: 100%;
	border-radius: 10px;
	cursor: pointer;
}

.close {
	top: -30px !important;
	right: -24px !important;
	left: auto !important;
	bottom: auto !important;
	z-index: 1;
}

/* .uploadImageWrapper ul li {
  justify-content: center;
} */

.iconButton {
	padding: 0 !important;
}

.iconButton span {
	font-size: 24px;
	padding: 0;
	margin: 0;
}

.filenameText {
	border-radius: 8px;
	background-color: #f0f2f5;
	color: #1f242d;
	text-align: left;
	align-items: center;
	padding: 4px 8px;
	/* margin: 12px; */
	width: 240px;
}

.raiseBa {
	width: 120px;
	height: 49px;
	align-self: "top";
	margin-top: 30px;
	background-color: #ff7d1f;
	color: #ffffff;
}

.raiseBa:hover {
	background-color: #ff7d1f;
	opacity: 0.6;
	border-radius: 12px;
}

.raiseBa span {
	font-weight: 700;
	font-size: 16px;
}

.uploadCta {
	min-width: 100px;
}

.uploadCta span {
	font-weight: 700;
}

.kycImage img {
	height: 60px;
	width: 100px;
	border-radius: 10px;
}

.kycImage object {
	height: 60px;
	width: 100px;
	border-radius: 10px;
}

.blurredImage {
	filter: blur(2px);
}

.ownershipTypeInput .k-form-field {
	margin-top: 0;
}

.ownershipTypeInput .k-form-field .k-label {
	text-align: left;
	width: max-content;
	font-size: 16px;
}

.ownershipTypeCheckbox .k-checkbox {
	margin-right: 10px;
}

.ownershipTypeCheckbox .k-checkbox:checked {
	background-color: #20c997;
	border-color: #20c997;
}

.alignCenter {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 18px;
}

.gridContent div {
	padding: 0 !important;
}

.imageUploadWrapper {
	text-align: center;
	padding: 24px;
	border: 2px dashed #f79009;
	border-radius: 12px;
	min-height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.imageUpload p {
	margin-bottom: 0;
}

.imageUpload img {
	margin: auto;
	margin-bottom: 24px;
	cursor: pointer;
	min-width: 184px;
	min-height: 115px;
}

.imageUpload input {
	display: none;
}

.customChips {
	border-radius: 100px;
	width: 90px;
	height: 42px;
	margin-right: 12px;
	/* padding: 12px 16px 12px 16px; */
	text-align: center;


	&:nth-child(n + 4) {
		margin-top: 8px;
		/* Add top-margin for the second row items */
	}
}

.selectedChip {
	background-color: #dc6803 !important;
	color: #ffffff;
	border: 0;
}

.unSelectedChip {
	background-color: #ffffff !important;
	color: #536179;
	border: 1px solid #536179;
}

.disabledChip {
	cursor: default;
}

.timeSlot {
	width: 147px;
}

.slotLabel {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #1f242d;
	margin-bottom: 10px !important;
}

.viewMore {
	padding: 0;
}

.viewMore span {
	font-weight: 700;
}

.markReason span {
	font-weight: 700;
}

.radio {
	margin-right: 10px;
}

.alignRight {
	text-align: right;
}

.titleBar {
	font-weight: 700;
	font-size: 20px;
	color: #343d4c;
}

.divider {
	border-bottom: 1px solid #e1e4ea;
	margin-top: 24px;
}

.pickupFormWrapper {
	background-color: #fff;
	height: 200px;
	/* min-height: calc(100vh - 126px); */
}

.pickupForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: baseline;
}

.pickupForm input {
	height: 44px;
}

.pickupForm input::placeholder {
	font-weight: 200;
}


.pickupForm>div {
	/* margin: 10px 20px 10px 0; */
	margin-top: 10px;
	width: 48%;
}

.pickupForm>div>label {
	font-size: 12px;
	font-weight: 500;
	color: #a4afc1;
}

.bookSlotLabel {
	font-size: 12px;
	font-weight: 500;
	color: #a4afc1;
	margin-bottom: 12px;
}

.customTitle {
	font-size: 20px;
	font-weight: 700;
	color: #343d4c;
}

.leftBorder {
	border-left: 1px solid #a4afc1;
	margin-left: 7px;
	padding-left: 6px;
}

.netPayableTopSpacing {
	padding-top: 10px;
}

.netPayableBottomSpacing {
	padding-bottom: 10px;
}

.netPayableBorder {
	border-top: 1px solid #e1e4ea;
}

.c24Quote {
	text-align-last: center;
	padding-bottom: 18px;
	padding-top: 5px;
	align-items: center;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid #e1e4ea;
}

.c24Quote span {
	font-weight: 700;
	font-size: 24px;
	margin-right: 10px;
}

.expandImage {
	transition: transform 0.1s ease;
	cursor: pointer;
}

.expandedImage {
	transform: scaleY(-1);
}

.auditImage {
	justify-self: center;
	width: max-content;
	align-self: center;
}

.auditCheckComplete {
	color: #039855;
}

.auditFlagged {
	color: #e39616;
}

.auditFailed {
	color: #d92d20;
}

.pickUpDialog>div {
	max-height: max-content !important;
}

.auditLoader {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 36px 12px;
	border: 1px solid #e1e4ea;
	border-radius: 8px;
	margin: 54px 12px;
}

.auditChecks {
	align-items: center;
	padding: 36px 12px;
	border: 1px solid #e1e4ea;
	border-radius: 8px;
	margin: 16px;
}

.colorRed {
	color: #d92d20;
}

.finalQuoteHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.finalQuotePllSwitch {
	display: flex;
	justify-content: space-between;
	border-radius: 30px;
	background-color: #e1e4ea;
	padding: 10px;
	width: 15%;
	height: 50px;
}

.inspectionItemHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff3cd;
	height: 45px;
	align-content: center;
	padding: 20px 12px;
	border-radius: 8px;
}

.expandedControl {
	border-radius: 4px !important;
	padding: unset !important;
	margin-bottom: 5px;
}

.low_margin_div {
	background: white;
	padding: 20px 12px 20px 12px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.inspection_report_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.inspection_report_view_report {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
	color: #0059A3;
	cursor: pointer;
  text-decoration: underline;
}

.quote_container{
  width: 100%;
  background: white;
  padding: 20px 12px 20px 12px;
  border-bottom: 1px solid #E6EEF6;
  border-radius:4px;
  color:#465166;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
}

.quote_container_data{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
}

.quote_container_data--label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #9EA7BB;
  margin-bottom: 4px;
}

.quote_container_data--value{
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin-left: 0px;
}

.quote_container_data--button{
  width: fit-content;
  height: 38px;
  padding: 7px 13px 7px 13px;
  border-radius: 4px;
  background: #0059A3;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.get_more_price_container{
  display: flex;
  flex-direction: column;
}

.edit_icon{
  color: #0059A3;
  margin-left: 5px;
  margin-top: -3px;
  cursor: pointer;
}

.last{
  border-bottom: none !important;
}

.expansion_header{
  margin: 0 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
}

.pr_automation :global(.k-tabstrip-content),.pr_automation :global(.k-tabstrip > .k-content){
  border:none;
  padding: 0 20px 0 0;
}
.pr_automation :global(.k-tabstrip-items){
  border: none;
  width: 250px;
  flex: 0;
  position: sticky;
  top: 140px;
}

.pr_automation :global(.k-tabstrip-items .k-link){
  color: #7E8594;
}

.pr_automation :global(.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item:active), .pr_automation :global(.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item.k-active){
  border: none !important;
}

.pr_automation :global(.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item:active), .pr_automation :global(.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item.k-active span){
  color: #003562;
  font-size: 16px;
}

.pr_automation :global(.k-tabstrip-items-wrapper .k-item:active), .pr_automation :global(.k-tabstrip-items-wrapper .k-item.k-active), .pr_automation :global(.k-tabstrip-items-wrapper .k-item.k-selected){
  border: none;
}
.pr_automation :global(.k-tabstrip-left > .k-tabstrip-items-wrapper){
  border: none;
}

.pr_automation :global(.k-tabstrip-items-wrapper .k-item:hover), .pr_automation :global(.k-tabstrip-items-wrapper .k-item.k-hover){
  color: #7E8594;
  border: none;
  text-decoration: underline;
}

.pr_automation :global(.k-card){
  background-color: white !important;
  border: 1px solid #E4E8EF;
  box-shadow: 0px 2px 120px 0px #C8D4DF40 !important;
  border-radius: 4px;
}

.pr_automation :global(.k-card-header ){
  background-color: #F9FCFF !important;
  color:#003562 !important;
}

.pr_automation :global(.k-expander-title){
  color:#003562 !important;
}

.pr_automation :global(.k-expander-header:hover,) .pr_automation :global(.k-expander-header.k-hover){
  background-color: #F9FCFF !important;
  color:#003562 !important;
}

.pr_automation :global(.k-expander.k-focus){
  box-shadow: none;
  border: 1px solid #E4E8EF;
}

.pr_automation :global(.k-expander.k-focus .k-expander-header), .pr_automation :global(.k-expanded .k-expander-header){
  background-color: #F9FCFF !important;
}

.acceptDNPButton{
  display: inline-block;
  width: fit-content;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  background: white;
  color: #0059A3;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border: 1px solid;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.loader{
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editClickedInput{
  padding: 5px 10px;
  width: 150px;
  border: 1px solid #ADC6DE;
  border-radius: 4px;
}

.editClickedInput:focus{
  outline-color: #ADC6DE;
}

.ocb--label{
  color: #465166;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 20px;
  margin-left: 5px;
}

.ocbLogsTable{
  width: 100%;
  border: 1px solid #E6EEF6;
  border-radius: 8px;
  overflow: hidden;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}

.ocbLogsHeader{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  background-color: #F8FBFF;
  border-bottom: 1px solid #E6EEF6;
  padding: 20px;
}

.ocbLogsHeader_h{
  width: calc((100% - (2* 16px)) / 3);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #465166;
}

.ocbLogsdata{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 20px;
}

.ocbLogsdata_d{
  width: calc((100% - (2* 16px)) / 3);
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #465166;
}

.ocbLogsdata:nth-child(odd) {
  background-color: #F9FCFF;
  ;
}

.ocbLogsdata:nth-child(even) {
  background-color: white;
}

.unathorized{
  min-height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.unathorized_403{
  font-size: 100px;
  font-weight: 700;
  line-height: 100px;
}

.unathorized_h3{
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
}

.unathorized_p{
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.nego_header_loader{
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expansion_panel_title{
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
}

.expansion_panel_title_check{
  height:20px;
  width:20px;
  text-align:center;
  border-radius:4px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.inspection_grid :global(.k-grid-content){
  padding-bottom: 0px !important;
}

.inspection_grid :global(.k-column-title){
  padding: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left !important;
  color: #465166 !important;
}

.inspection_grid :global(td){
  padding: 10px !important;
  text-align: left !important;
  color: #465166 !important;
  background-color: white !important;
  border-bottom: 1px solid #E4E8EF !important;
  font-family: Inter;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}

.inspection_grid :global(td),.inspection_grid :global(th){
  border:none !important;
}

.inspection_grid :global(.k-grid .k-table-td), .inspection_grid :global(.k-grid .k-table-th), .inspection_grid :global(.k-grid td){
  border-bottom: 1px solid #E4E8EF !important;
  border-color: #E4E8EF !important;
}

.inspection_grid :global(.k-column-title),.inspection_grid :global(.k-link){
  padding: 5px;
  text-align: left !important;
  justify-content: flex-start !important;
  color: #465166;
  word-break: break-word;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}

.inspection_grid :global(.k-table .k-table-thead){
  background: #F8FBFF;
}

.inspection_grid :global(.k-grid){
  border-radius: 4px;
  border-bottom: 1px solid #E6EEF6 !important;
}

.businessApproval_grid :global(.k-grid-content){
  padding-bottom: 0px !important;
}

.businessApproval_grid :global(.k-column-title){
  padding: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left !important;
  color: #465166 !important;
}

.businessApproval_grid :global(td){
  padding: 13px 10px !important;
  text-align: left !important;
  color: #465166 !important;
  background-color: white !important;
  border-bottom: 1px solid #E4E8EF !important;
  font-family: Inter;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}

.businessApproval_grid :global(td),.businessApproval_grid :global(th){
  border:none !important;
}

.businessApproval_grid :global(.k-grid .k-table-td), .businessApproval_grid :global(.k-grid .k-table-th), .businessApproval_grid :global(.k-grid td){
  border-bottom: 1px solid #E4E8EF !important;
  border-color: #E4E8EF !important;
}

.businessApproval_grid :global(.k-column-title),.businessApproval_grid :global(.k-link){
  padding: 5px;
  text-align: left !important;
  justify-content: flex-start !important;
  color: #465166;
  word-break: break-word;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}

.businessApproval_grid :global(.k-table .k-table-thead){
  background: #F8FBFF;
}

.businessApproval_grid :global(.k-grid){
  border-radius: 4px;
  border-bottom: 1px solid #E6EEF6 !important;
}

.price_breakdown_div  :global(.k-card-header),
.price_breakdown_div  :global(.k-card-header .k-card-title),
.price_breakdown_div  :global(.k-card-header .k-card-subtitle)
.price_breakdown_div  :global(.k-card-header .k-card-title .k-font-medium){
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(0deg, rgba(248, 251, 255, 0.4), rgba(248, 251, 255, 0.4)) !important;
  font-family: Inter !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  text-align: left !important;  
  color: #003E72 !important;
  padding: 5px !important;
}

.price_breakdown_div  :global(.k-card-body){
  padding: 5px 12px !important;
}

.price_breakdown_div :global(.k-listview-content *){
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #465166;
  padding: 1px 0px;
}