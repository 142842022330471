@import "../common/assets/styles/variables.scss";

.all-pais-cars {
    .title-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-text {
            font-size: 19px;
            font-weight: 400;
            line-height: 25.27px;
            color: $titleColor;
        }
    }

    .ongoing-car-cards {
        column-gap: 24px;
        box-shadow: 0px 2px 4px 0px $ongoingCardsBoxShadowColor;
        padding: 16px;
        margin-bottom: 24px;
    }

    .recommended-cars {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #465166;
    }

    .all-cars-table {
        padding: 16px;
        background: $allCarsTableBgColor;
    }

}


.all-cars-filters {
    display: flex;
    align-items: center;
    flex-flow: row;
    column-gap: 10px;
}
.all-cars-table {
    .k-table{
        .k-table-th{
            border-width: 0 !important;
            border-right: 1px solid $tableGridBorderColor !important;
            &:last-child{
                border-right: none !important;
            }
        }
        td{
            border-width: 0 !important;
            border-right: 1px solid $tableGridBorderColor !important;
            border-bottom: 1px solid $tableGridBorderColor !important;
            &:last-child{
                border-right: none !important;
                // border-bottom: none !important;
            }
        }
        
       
    }
    .k-grid{
        border-color:  $tableGridBorderColor !important;
        .k-grid-header{
        border-color:  $tableGridBorderColor !important;
    
        }
        .k-grid-header-wrap{
        border-color:  $tableGridBorderColor !important;
    
        }
        .k-grid-content{
            padding-bottom: 0px !important;
        }
    }
    }
    .all-pais-car{
        .title-bar {
            background: #F8F9FA;
            display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;;
        }
    }

    .no-pais-data{
        font-size: 19px;
        font-weight: 400;
        line-height: 25.27px;
        color: #003e72;
        padding-bottom: 16px;
    }