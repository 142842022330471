.wrapper {
    cursor: pointer;
}
.backArrowIcon {
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 18px;
    color: 003E72;
    cursor: pointer;
}
.label {
    color: #003E72;
    font-size: 20px;
    margin-right: 40px;
}