.action-cell-container{
  display: flex;
  gap: 12px;
  justify-content: center;
}

.accept-btn{
  background-color: transparent;
  width: 100%;
  &:hover{
    background-color:green;
    color: white;
  }
}

.reject-btn{
  width: 100%;
  background-color: transparent;
  &:hover{
    background-color:red;
    color: white;
  }
}

.confirmation-text-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}


.dialog-container {
  z-index: 999 !important;
}

.dialog-container :global(.k-window .k-actions) {
  justify-content: flex-end ;
}

.date-cell{
  word-break: break-word;
  border: 2px solid red;
}


