.status-container{

  display: flex;
  justify-content: center;

  .custom-chip {
    padding: 2px 8px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    gap: 6px;
  

    .bulletElement {
      width: 8px;
      height: 8px;
      border-radius: 16px;
    }
  

    .status-text {
      font-size: 12px;
    }
  
    @mixin status-style($bg-color, $bullet-color, $text-color) {
      background-color: $bg-color;
      .bulletElement {
        background-color: $bullet-color;
      }
      .status-text {
        color: $text-color;
      }
    }
  
    &.processing {
      @include status-style(#F8F9FC, #3E4784, #3E4784);
    }
  
    &.completed {
      @include status-style(#E8F5E9, #4CAF50, #4CAF50);
    }
  
    &.failed, &.system-error {
      @include status-style(#FFEBEE, #F44336, #F44336);
    }
  
    &.partially-failed {
      @include status-style(#FFFAEB, #DC6803, #DC6803);
    }
  }
  
}

.comment-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  word-break: break-word;
  gap: 40px;

  .download-icon{
    cursor: pointer;
  }

  .download-icon-disable{
    cursor: not-allowed;
  }
}