.vahan_check_grid :global(.k-grid-content){
  padding-bottom: 0px !important;
}

.vahan_check_grid :global(.k-column-title){
  padding: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left !important;
  color: #465166 !important;
}

.vahan_check_grid :global(td){
  padding: 13px 10px !important;
  text-align: left !important;
  color: #465166 !important;
  background-color: white !important;
  border-bottom: 1px solid #E4E8EF !important;
  font-family: Inter;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}

.vahan_check_grid :global(td),.vahan_check_grid :global(th){
  border:none !important;
}

.vahan_check_grid :global(.k-grid .k-table-td), .vahan_check_grid :global(.k-grid .k-table-th), .vahan_check_grid :global(.k-grid td){
  border-bottom: 1px solid #E4E8EF !important;
  border-color: #E4E8EF !important;
}

.vahan_check_grid :global(.k-column-title),.vahan_check_grid :global(.k-link){
  padding: 5px;
  text-align: left !important;
  justify-content: flex-start !important;
  color: #465166;
  word-break: break-word;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left;
}

.vahan_check_grid :global(.k-table .k-table-thead){
  background: #F8FBFF;
}

.vahan_check_grid :global(.k-grid){
  border-radius: 4px;
  border-bottom: 1px solid #E6EEF6 !important;
}

.vahanCheckComplted{
  background: #ECFDF3;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #067647;  
  padding: 8px 12px;
  border-radius: 54px;
}

.vahanCheckPending{
  background: #FFFAEB;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #DC6803;
  padding: 8px 12px;
  border-radius: 54px;
}

.reRaiseButton{
  background: #0059A3;
  border: 1px solid #005399;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: white;
}

.reRaiseButton:hover{
  background: #0059A3;
  border: 1px solid #005399;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: white;
}

.viewAllVahanButton{
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #0059A3;
  text-decoration: underline;
  background-color: transparent;
  border: none;
}
.viewAllVahanButton:hover{
  background-color: transparent !important;
  outline-color: transparent  ;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #0059A3;
  text-decoration: underline;
  border-color: transparent;
}

.vahanContent{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.vahanContent_part1{
  border-bottom: 1px solid #E6EEF6;
  padding-bottom: 30px;
}
