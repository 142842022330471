.head{
  min-height: 500px;
}

.head_header{
  display: flex;
}

.head_grid{
  display: flex;
  flex-direction: column;
}

.title_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 24px;
  font-weight: 700;
  font-family: system-ui;
}

.head_grid :global(.k-column-title),.head_grid :global(td),.head_grid :global(.k-link){
  padding: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left !important;
  justify-content: flex-start !important;
  color: #121212;
  word-break: break-word;
}

.head_grid :global(td div){
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.unathorized{
  min-height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.unathorized_404{
  font-size: 100px;
  font-weight: 700;
  line-height: 100px;
}

.unathorized_h3{
  font-size: 38px;
  font-weight: 700;
  line-height: 38px;
}

.unathorized_p{
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.head_tags_container{
}

.head_tags_data{
  width: fit-content;
  position: relative;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  padding: 5px 16px;
  border-radius: 16px;
  cursor: pointer;
}

.head_tags_data:not(:last-child){
  margin-bottom: 10px;
}

.head_grid :global(.k-cell-inner){
  align-items: center !important;
}

.head_grid :global(.k-pager-sizes){
  display: none;
}

.head_grid :global(.k-grid-content){
  padding-bottom: 0px !important;
}

.head_grid :global(.k-column-title){
  padding: 0;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left !important;
  color: #465166 !important;
}

.head_grid :global(td){
  padding: 13.5px !important;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.52px;
  text-align: left !important;
  color: #465166 !important;
}

.head_grid :global(td),.head_grid :global(th){
  border:none !important;
}

.head_grid :global(td){
  background-color: white !important;
  border-bottom: 1px solid #E4E8EF !important;
  /* border-color: #E4E8EF !important; */
}

.head_grid :global(.k-grid .k-table-td), .head_grid :global(.k-grid .k-table-th), .head_grid :global(.k-grid td){
  border-bottom: 1px solid #E4E8EF !important;
  border-color: #E4E8EF !important;
}

.td_part1{
  font-family: Inter;
  font-size: 13px;
  font-weight: 400 !important;
  line-height: 15.73px;
  text-align: left;
  color: #465166;  
}

.td_part2{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400 !important;
  line-height: 14.52px;
  text-align: left;
  color: #8B8B8E;  
}

.selected-filter {
	color: #ebf5ff;
	background-image: url("../../../../assets/img/filter.svg") !important;
	background-repeat: no-repeat;
	background-position: 100% 10%;
	padding-right: 20px;
	background-origin: content-box;
}

.de-selected-filter {
	color: #003e72;
	background-image: unset !important;
	padding-right: unset !important;
}

.checkboxFilter ~ :global(.k-actions), .checkboxFilter ~ :global(.k-actions-stretched),
.dropdownFilter ~ :global(.k-actions), .dropdownFilter ~ :global(.k-actions-stretched),
.inputBoxFilter ~ :global(.k-actions), .inputBoxFilter ~ :global(.k-actions-stretched)
{
  display: none;
}


.filteractions{
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.filteractions_filter{
  background: #0059A3 !important;
  color: white;
  border: none;
  border-radius: 4px;
  flex: 1;
  height: 30px;
}

.filteractions_clear{
  background: white !important;
  color: #0059A3;
  border: none;
  border: 1px solid #0059A3;
  border-radius: 4px;
  flex: 1;
  height: 30px;
}

.inputFilter{
  padding: 5px 10px;
  width: 150px;
  border: 1px solid #ADC6DE;
  border-radius: 4px;
  width: 100%;
}

.inputFilter:focus,.inputFilter:active{
  border: 1px solid #ADC6DE;
  outline: none;
}

.priority_icon{
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 40px;
  display: inline-block;
}

.priority_div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.negoStatus_div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding:10px;
  border-radius:30px;
  width:fit-content;
}

.negoStatus_icon{
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  height: 20px;
  display: inline-block;
  transform: scale(1.5);
}

.lastCall_div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lastCall_icon{
  background-repeat: no-repeat;
  background-size: cover;
  width: 15px;
  height: 20px;
  display: inline-block;
  transform: scale(2.5);
}


.title_div_actions{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.title_div_actions_label,.title_div_actions_button{
  background: #0059A3 !important;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.title_div_actions_input{
  display:none
}