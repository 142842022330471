.tab_strips_content :global(.k-card-header),
.tab_strips_content :global(.k-card-header .k-card-title),
.tab_strips_content :global(.k-card-header .k-card-subtitle){
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 16.94px !important;
  text-align: left !important;
  color:#003E72 !important;
}

.tab_strips_content :global(.k-font-size-xl){
  font-size: inherit !important;
}

.tab_strips_content :global(.k-expande){
  background: #F8FBFF !important;
  border-radius: 4px !important;
}

.tab_strips_content :global(.k-expander-header){
  background: #F8FBFF !important;
}

.main_page_loader{
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab_strips_content :global(.input-field-disabled ){
  color: #7E8594;
  cursor: not-allowed !important;
}