@import "variables.scss";

.app-primary-btn {
    background-color: $primaryBtnBgColor;
    border-color: $primaryBtnBgColor;

    &:hover {
        border-color: $primaryBtnHoverBorderColor;
        background-color: $primaryBtnHoverBgColor;
        color: $primaryBtnHoverTextColor;
    }

    &:focus {
        box-shadow: none;
    }
}

.app-secondary-btn {
    background-color: $secondaryBtnBgColor;
    border-color: $secondaryBtnBgColor;
    color: $secondaryBtnTextColor;
    box-shadow: 0px 1px 5px 0px $secondaryBtnBoxShadowColor;
}

.app-error-btn {
    color: $errorBtnColor;
    background-color: $errorBtnBgColor;
    border-color: $errorBtnBgColor;

    .k-button-text {
        line-height: 14px;
    }

    &:hover {
        background-color: $errorBtnBgColor;
        border-color: $errorBtnBgColor;
    }

    &:focus {
        box-shadow: none;
    }
}

.app-warning-btn {
    color: $warningBtnColor;
    border: 1px solid $warningBtnColor;
    background-color: $warningBtnBgColor;

    &:hover {
        border-color: $warningBtnHoverColor;
        background-color: $warningBtnHoverColor;
        color: $warningBtnBgColor;
    }

    &:focus {
        box-shadow: none;
    }
}

.filter-btn-primary {
    border: 1px solid $filterBtnBorderColor;
    padding: 8px;
    cursor: pointer;

    .k-icon {
        color: $filterBtnIconColor;
        width: 12px;
        height: 13px;
    }

    &:hover {
        border: 1px solid $filterBtnHoverBorderColor;

        .k-icon {
            color: $filterBtnIconHoverColor;
        }
    }

    &.disabled {
        cursor: not-allowed;
        border: 1px solid $dropdownBorderColor;
        opacity: 0.5;
    
        .k-icon {
          color: $dropdownBorderColor; 
        }
    
      }

    
}



.app-share-btn {
    // font-size: 12px;
    // font-weight: 500;
    // line-height: 20px;
    color: $shareBtnColor;
    display: flex;
    flex-flow: row-reverse;

    .k-button-text {
        font-weight: 500;
        line-height: 20px;
    }
}

.app-checkbox {
    .k-checkbox {
        border-color: $checkBoxBorderColor;
        width: 18px;
        height: 18px;

        &:focus {
            box-shadow: none;
        }
    }

    .k-checkbox:checked,
    .k-checkbox.k-checked {
        border-color: $checkBoxSelectedBgColor;
        color: $checkBoxSelectedColor;
        background-color: $checkBoxSelectedBgColor;
    }
}

.app-link {
    font-size: 14px;
    font-weight: 700;
    line-height: 18.62px;
    color: $linkBtnColor;
    cursor: pointer;

    &:hover {
        color: $linkBtnHoverColor;
    }
}

.app-cancel-btn {
    background-color: $cancelBtnBgColor;
    border-color: $cancelBtnBorderColor;
    color: $cancelBtnColor;

    &:hover {
        background-color: $cancelBtnHoverBgColor;
        border-color: $cancelBtnHoverBorderColor;
    }
}

.app-search-input {
    border: 1px solid $searchInputBorderColor;
    min-width: 224px;
    color: $searchInputColor;
    font-size: 16px;
    padding: 2.5px;
    background-color: $searchInputBgColor !important;
    border-radius: 4px;
    display: flex;
    align-items: center;

    .k-input-inner{
        padding: 6px 2px;
    }

    .search-icon,
    .clear-icon {
        font-size: 18px;
        margin: 0 5px;
        font-weight: 600;
        transition: color 0.3s ease;  

        &:hover {
            color: $searchInputHoverBorderColor;
        }
    }

    &:hover {
        border: 1px solid $searchInputHoverBorderColor;
    }

    &:focus,
    &:focus-within {
        border: 1px solid $searchInputHoverBorderColor;
        box-shadow: none;
    }

    .k-input-solid {
        &:focus-within {
            border: 1px solid $searchInputBorderColor;
        }
    }
}



.app-slider{
    .k-scrollview-pageable > .k-button.k-primary, .k-scrollview-nav > .k-link.k-primary{
        background: $primaryColor;
        border: $primaryColor;
    }
}

.share-button{
    color:#fff
}