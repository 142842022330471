@import "variables.scss";
@import "car-card.scss";
@import "app-breadcumb.scss";
@import "tabs.scss";
@import "table.scss";
@import "form-controls.scss";
@import "app-chips.scss";
@import "dropdowns.scss";
@import "plane-grid.scss";
@import "rating.scss";
@import "table-grid";
@import "app-modal";
@import "tooltip.scss";
@import "scrollBar.scss";
@import "app-loader.scss";
.flex-center-between{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-center-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-center{
    display: flex;
    align-items: center;
}
.flex-between{
    display: flex;
    justify-content: space-between;
}
.flex-row{
    display: flex;
    flex-flow: row;
}
body{
    background-color:  $appBodyBgColor !important;
}
.page-details{
  
   @media screen and (min-width: 768px){
    padding: 12px;
   }
   @media screen and (min-width: 1280px) {
    padding: $layoutPadding;
    }
}
.align-center{
    display: flex;
    align-items: center;
}
.font-medium{
    font-weight: 500;
}
.word-space-left{
    margin-left: 4px;
}

.relative{
    position: relative;
}

.page-details-layout{
    min-height: calc(100vh - 160px);
}

.w-100{
    width: 100%;
}

.flex{
    display: flex;
}

.pt-10{
    padding-top: 10px;
}
.app-table{
    .relative{
        position: relative !important;
    }
}
