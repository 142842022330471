@import '../../common/assets/styles/variables.scss';

.call-quality-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .quality-number{
    font-weight: 500;
    font-size: 12px;
  }

.progress-bar-container{
  height: 4px;



  .k-progress-status{
    display: none;
  }

}

  .call-quality-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }

  .disabled-quality-icon{
    color: $cancelBtnHoverBorderColor;
    cursor: not-allowed;
  }

}