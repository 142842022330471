.expanded_panel {
    background-color: white;
}

.activity_feed :global(.k-expander:not(.k-expanded) + .k-expander:not(.k-expanded)), .activity_feed :global(.k-expander){
  border:1px solid #E6EEF6;
}
.activity_feed :global(.k-expander-title){
  font-family: Inter;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
}

.head_ss_container{
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 10px;
  left: 70px;
  padding: 12px;
  border: 0.4px solid #C7C8C9;
  box-shadow: 4px 4px 8px 0px #00000014;
  border-radius: 4px;
}
.head_ss_container_header{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.62px;
  color:#595C5F;
  margin: 0px !important;
}
.head_ss_container_overallScore{
  display: flex;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 65%, transparent 65% 100%), conic-gradient(#6CA9FF 72%, #81D4FE33 0);
  justify-content: center;
}
.head_ss_container_content{
  width: 100%;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px #00000014;
  border: 0.4px solid #C7C8C9;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
}
.head_ss_container_remark{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.96px;
  color: #212529;
  margin: 0px;
  margin-bottom: 10px;
}

.head_ss_content_collection{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.head_ss_container_data{
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  gap: 10px;
}

.head_ss_container_data:not(:last-child){
  border-bottom: 0.4px solid #C7C8C9;
}

.head_ss_container_data:last-child{
  border-bottom: unset;
}

.head_ss_container_parameter{
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 13.3px;
  color:#595C5F;
  margin: 0 !important;
  text-align: left;
}
.head_ss_container_score{
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 13.3px;
  margin: 0 !important;
}
.head_ss_container_btn{
  width: 120px;
  height: 26px;
  padding: 6px 2px;
  border-radius:8px;
  border: 1px solid #0A6FFE4D;
  box-shadow: 4px 4px 4px 0px #0000000A;
  background-color: #0A6FFE1A;
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 13.3px;
  text-align: center;
  color: #0A6FFE;
  margin: auto;
}