.verified {
	background-color: #A4FFD487;
	color: #00BA67 !important;
}

.underVerification {
	background-color: #F69B0033;
	color: #F69B00 !important;
}

.verificationFailed {
	background-color: #EB575733;
	color: #EB5757 !important;
}

.pending {
	background-color: #BEE3F8;
	color: #2B6CB0 !important;
}

.wFit{
	width: fit-content;
}

.docSubmitBtn{
	width: fit-content;
	margin-top: 1rem !important;
	margin-left: auto !important;
}

.hiddenInput{
	width: 0 !important;
	height: 0 !important;
}

.docImg{
	border: 1px solid black;
	width: auto;
	max-height: 50vh;
}

.docPdf{
	width: 50vw;
	height: 70vh;
}

.actionBtnContainer{
	flex: none;
	position: relative;
	width: 140px !important;
}

.confirmModalLoader{
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.docLoader {
	width: 400px;
	height: 400px;
	display: grid;
	place-items: center;
	margin-inline: auto;
}