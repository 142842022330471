@import "../../../common/assets/styles/variables.scss";

.app-rating {
    .k-rating-container {
      margin-inline: 0;
  
      .k-rating-item {
        padding: 2px;
      }
    }
  
    .k-svg-icon {
      width: 16px;
      height: 16px;
    }
  
    &.app-rating-low .k-selected {
      color: $lowRatingColor !important;
    }
  
    &.app-rating-medium .k-selected {
      color: #F4B242 !important;
    }
  
    &.app-rating-high .k-selected {
      color: $highRatingColor !important;
    }
  }
  
  .app-rating-secondary {
    color: $secondaryRatingColor;
  }
  