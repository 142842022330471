.notification {
    margin-top: 20px;
    padding: 10px;
    color: black;
    border-radius: 5px;
    z-index: 1000;
    top: 70px;
    position: fixed;
    min-width: 22em;
    left: 50%;
    transform: translate(-50%, 0);
}

.notification.info {
    background-color: #90ee90;
}

.notification.danger {
    background-color: #ee9090;
}