.head {
    width: 100%;
    min-height: 100px;
    background: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.head_collections {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.head_collections--error {
    font-size: 14px;
    font-weight: 550;
    margin: auto !important;
}

.head_call_logs_collection{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.head_call_logs_collection_singleLog{
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid #ADC6DE;
  cursor: pointer;
  color: #003562 !important;
}

.head_call_logs_collection_singleLog--active{
  background-color: #F9FCFF !important;
  color: #003562 !important;
  font-weight: 700;
}