.main_div{
  background: white;
  width: 250px;
  position: fixed;
  left: 70px;
  bottom: 10px;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #E6EEF6;
  z-index: 99999;
}

.head{
  min-height: 100px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}

.title{
  font-size: 16px;
  font-weight: bold;
  color: #003562 !important;
}

.close_btn{
  display: flex;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
  font-weight: 700;
  width: fit-content;
  margin-left: auto;
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 16px;
  color: #9EA7BB;
  cursor: pointer;
}

.close_btn:hover{
  border: 1px solid #E6EEF6;
  color: #003562;
}

.PhoneControls{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.PhoneControls :global(p){
  margin: 0;
}

.incomingCallDiv{
  width: 100%;
}

.incomingCallHeader{
  font-size: 14px;
  font-weight: bold;
  color: #003562 !important;
}

.incomingCallNumber{
  font-size: 14px;
  font-weight: bold;
  color: #003562 !important;
}

.phoneNumberInput{
  width: 100%;
  border: 1px solid #E6EEF6;
  outline: none;
  color: #003562;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
}

.phoneNumberInput:focus{
  border: 1px solid #E6EEF6;
}

.phoneControlButtons{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.phoneControlButtons :global(button){
  flex-grow: 1;
  padding: 10px;
  border: none;
  margin: 0;
  border-radius: 8px;
}

.call{
  background-color: #66bb6a;
  color: white;
}

.accept{
  background-color: #66bb6a;
  color: white;
}

.hangup{
  background-color: #d9534f;
  color: white;
}

.Mute{
  background-color: #66bb6a;
  color: white;
}

.Unmute{
  background-color: #d9534f;
  color: white;
}

.Hold{
  background-color: #66bb6a;
  color: white;
}

.UnHold{
  background-color: #d9534f;
  color: white;
}