.truncated-content-wrapper {
    display: flex;
    align-items: center; /* Vertically center the content */
    height: 100%;
}

.truncated-content {
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2em; /* Line height should match the content */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    /* No need to set max-height here, as we are controlling it via -webkit-line-clamp */
}
