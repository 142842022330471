.card-container{
  display: flex;
  flex-direction: column;
  
  .card-header{
    display: flex;
    justify-content: center;
  
    .gauge-container{
      margin: 16px 0 16px 0;
      display: flex;
      flex-direction: column;

      .k-arcgauge-label{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        h3{
          color: #595C5F !important;
          font-size: 14px;

        }
      }

    }

    .score-status{
      text-align: center;
      
    }
    
    .score-information{
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;

      .call-score{
        width: 100%;
        display: flex;
        white-space: nowrap;
        gap: 10px;
        font-size: 12px;
        padding: 4px 6px;
        border-radius: 8px;
        border: 0.5px solid #C7C8C9;
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.08);

        .score-value{
          width: 100%;
          font-size: 12px;
          font-weight:700;
          text-align: end;
        }

      }
    }
   
  }

  .score-card-chip{
    border: 1px;
    border-radius: 16px;
    font-size: 12px;
    padding: 4px 8px;
    font-weight: 700;
    
  }

  .score-modal-table{

    .k-table-td{
      word-break:break-word
    }
    .score-modal-chips{
      border-radius: 16px;
      font-weight: 700;
      padding: 4px 8px;
      font-size: 12px;
    }
  }
}