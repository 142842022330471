.listingHeader {
	font-size: 22px;
	font-weight: 400;
	line-height: 34px;
	letter-spacing: 0em;
	color: #003e72;
}
.search {
	display: flex;
	justify-content: space-around;
}
.formulaBtn {
	display: flex;
	justify-content: flex-end;
}
.deleteformulaBtn {
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	margin-top: 30px;
}
.validityContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
	background: #ebf5ff;
}
.radiogroup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
}
.radiogroup .k-form-field {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.radiogroup .k-form-field .k-label {
	margin-right: 15px;
}

.radiogroup span {
	margin-left: 1px;
}

.rangeValidityContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0px 10px;
	background: #ebf5ff;
}

.userSearchInput {
	/* width: 50%; */
	/* height: auto; */
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 8px;
	padding: 6px;
	/* border: 1px solid #ccc; */
	border-radius: 5px;
	/* box-shadow: 0 1px 5px 0; */
	border: 0.1px solid;
}
.userSearchInput input {
	border: none;
	height: 20px;
	padding: 5px;
}
.userSearchInput input:focus {
	outline: none;
}
.suggestionsList {
	max-height: 100px;
	overflow-y: scroll;
	list-style: none;
	padding: 0;
	margin: 0;
	background-color: hsl(0, 0%, 100%);
	border: 1px solid gray;
}
.suggestionsList li {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	padding: 8px 10px;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
	z-index: 1;
}
.suggestionsList li:last-child {
	border-bottom: none;
}
.suggestionsList li:hover {
	background-color: #ccc;
}
.gridLayoutRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
