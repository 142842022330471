.call-tooltip{
    z-index: 999 !important;
  
    .k-tooltip{
      background: $callTooltipBgColor !important;
      border-radius: 2px;
      width: auto;
      height: auto;
    }
  }
  
  .primary-tooltip{
    .k-tooltip{
      background: $primaryTooltipBgColor !important;
      border-radius: 2px;
      color: $primaryTooltipTextColor;
    }
  }
  .error-tooltip{
    .k-tooltip{
      background: $errorTooltipBgColor !important;
      color: $primaryTooltipTextColor;
      border-radius: 2px;
    }
  }