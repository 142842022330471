@import "../common/assets/styles/variables.scss";

.car-details-container{
    max-width: 45%;
    width: 45%;
    background-color: $filterBgColor;
    height: 100vh;
    z-index: 3;
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px;
    display: flex;
    flex-flow: column;

    
    .close-icon {
        color: $filterCloseIconColor;
        font-size: 22px;
        margin: 0;
    }


.car-details{
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 16px 0;
    height: 100vh;
    
    .car-image-container{
            gap: 12px;
            max-height: 280px;
            margin-top: 8px;

            .carousel-container{
                background-color: $cardHighlightBgColor;
                height: 280px;
                border-radius: 8px;

                .car-img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 8px;
                }
    
            }
            .k-scrollview-prev, .k-scrollview-next {
                color: $searchInputBorderColor;
                
                &:hover {
                    color: $chipLabelColor;

                }
            }
            

        

           
}

      .car-content{
        width: 100%;
        margin: 15px 0;
        gap: 15px;
        position: relative;
        
        .car-title {
            font-weight: 400;
            line-height: 18.75px;
            font-size: 20px;
            line-height: 30px;
            color: $titleColor;
            width: 75%;
            max-width: 75%;
            word-break: break-word;
            text-align: left;
            }

            .car-sub-title {
                font-size: 12px;
                line-height: 20px;
                color: $secondaryTitleColor;
                }

        .car-highlights {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 12px;
            margin-top: 10px;
            margin-bottom:10px ;

            .highlight-chip {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $highlightBgColor;
                color: $highlightTextColor;
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                word-break: break-word;
            }
    
            }
            
        .hours-left {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: $hoursLeftIndicatorColor;
            white-space: nowrap;
            }
            
        .pdf-icon {
            color: $primaryColor;
            padding-left: 4px;
            cursor: pointer;
        }


        .bid-block {
            position: absolute;
            // padding: 4px;
            flex-flow: column;
            }

        .dealers-info-block {
            display: flex;
            align-items: center;
            padding: 8px 0;
        
            .label {
                white-space: nowrap;
                color: $secondaryTitleColor;
                font-weight: 500;
            }
        }
                .app-tabs {
                    gap: $blocksPadding;;
                    margin-top: $blocksPadding;

                    .k-content{
                        overflow: unset;
            
                        .k-animation-container{
                            overflow: unset;
                        }
                    }
                    }
    }

    .flag-content-container{
        margin-top: 12px;
        display: flex;
        flex-direction: column;
    
        .flag-content{
            display: flex;
            align-items: center;
            gap: 8px;
            word-break: break-word;

        }
        .flag-text{
            color: $titleColor;
            font-weight: 700;

        }

}


}
}

.dealers-info-block {
    display: flex;
    align-items: center;
    padding: 16px 0;

    .label {
        padding-right: 16px;
        color: $secondaryTitleColor;
        font-size: 14px;
        font-weight: 500;
    }
}
.bid-icon{
    font-size: 20px;
    color: #F31700;
}