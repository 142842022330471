@import "variables.scss";

.plane-grid {
    
    display: flex;
    width: 100%;
    border: 1px solid $tableGridBorderColor;
    border-bottom-width: 0;
    align-items: stretch;
    text-align: left;
    &:last-child {
        border-bottom-width: 1px;
    }

    .label {
        width: 50%;
        padding: 8px 12px;
        background: #F8FBFF;
        font-weight: 500;
        line-height: 22px;
        color: $planeGridLabelColor;
        display: flex;
        align-items: stretch;
        
    }

    .value {
        width: 50%;
        padding: 8px 12px;
        background: #FFFFFF;
        border-left-width: 1px !important;
        border-color: $tableGridBorderColor;
        border-style: solid;
        font-weight: 400;
        line-height: 22px;
        color: $planeGridLabelColor;
        border-width: 0px;
        min-height: 38px;
        display: flex;
        align-items: center;
        // &:last-child{
        //     border-bottom-width: 1px;
        //     border-left: 0;
        // }
    }
}