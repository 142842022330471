@import '../../../common/assets/styles/variables.scss';

.snackbar-alert{
  position: fixed;
  width: 600px;
  top: 86px;
  right: 14px;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.25);
  padding: 18px;
}

.snackbar-container{
  border-radius: 12px;
  background-color: white;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .snackbar-header{
    display: flex;
    align-items: center;
    gap: 8px;

    .snackbar-title{
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .snackbar-heading-text{
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
      }
      .snackbar-close-icon{
        cursor: pointer;
      }
      .snackbar-date-time{
        color: #9A9FAA;
      }

    }

    .snackbar-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 32px;
      border-radius: 40px;
    }
  }

  .snackbar-body{
    display: flex;
    flex-direction: column;

    .snackbar-text{
      color: $snackbarTextColor;
      word-break: break-word;
    }
   
  }

  .timer-line {
    position: absolute;
    bottom:0;
    left: 5px;
    right:5px;
    height: 4px;
    background-color:$breadcrumbLinkColor;
    animation: shrink linear forwards;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  @keyframes shrink {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }

  .snackbar-footer-button{
    display: flex;
    gap: 12px;
    margin-top: 20px;
  }

  .view-car-btn{
    background-color: $primaryColor;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
  }

  .mark-as-imp-btn{
    border: 2px solid #D6D6D6;

  }

  .k-button-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    cursor: pointer;
    font-weight: 500;
  }

 
  
  
}