.car-tag-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px 1px 8px 1px;
  padding: 4px 6px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  color: #fff;

  .indicator-text {
    font-weight: 600;
    font-size: 12px;
  }

  &::after{
    position: absolute;
    bottom: -5px;
    left: 2px;
    transform: rotate(225deg);
    border-width: 6px; 
    border-style: solid;
    border-color: inherit transparent transparent transparent;
  }

}
