.head {
    width: 100%;
    min-height: 100px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.head_collections {
    width: 100%;
    padding: 10px 30px;
}

.head_collections--error {
    font-size: 14px;
    font-weight: 550;
}

.subParameter{
  text-align: left !important;
}

.score{
  text-align: center !important;
}

.head__data{
  width: 100%;
}

.scorecard_grid :global(.k-grid .k-grid-header .k-table-th){
  background-color: white;
  border: none;
  padding-bottom: 20px;
}

.scorecard_grid :global(.k-grid .k-table-td),.scorecard_grid :global(td){
  background: white;
  border: none;
}

.scorecard_grid :global(.k-grid){
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}
.progress-bar {
  display: flex;
  align-items: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 65%, transparent 65% 100%), conic-gradient(#6CA9FF 72%, #81D4FE33 0);
  justify-content: center;
}

.head_scorecard_header{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.head_scorecard_header_content{
  color: #595C5F;
}

.head_scorecard_header_content_p1{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.62px;
  color: #212529;
  margin-bottom: 5px !important;
}

.head_scorecard_header_content_p2{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.96px;
  color: #212529;
  margin-bottom: 0px !important;
}

.scorecard_grid :global(td),.scorecard_grid :global(th){
  word-break: break-word !important;
}