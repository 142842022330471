.dynamic_List_Cell{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 21px;
    max-width: 180px;
}

.dynamic_List_Cell .dynamic_List_Cell_title{
    font-size: 14px;
    color: #465166;
    font-weight: 500;
    margin: 5px 0px;
}

.dynamic_List_Cell .dynamic_List_Cell_value{
    font-size: 14px;
    color: #7E8594;
    font-weight: 400;
    margin-bottom: 15px;
    max-width: 180px;
}

