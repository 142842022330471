
.buy-back-container{
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

}

.buy-back-container :global(.k-table){
  width: 100% !important;
}

.buy-back-container :global(.k-column-title){
  text-align: center;
  word-break: break-word;
}

.panel-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

}

.panel-name{
  font-size: 20px;
  font-weight: 600;
}


.table-container :global(.gridData){
  margin-top: 0;
}

.table-container :global(.gridData .k-grid .k-table-row ){
 font-weight: 500;
}


.table-container :global(.gridData .k-grid .k-grid-content){
  padding-bottom: 0;
}

.table-container :global(.k-pager-sizes ){
  width: 20% ;
}
.table-container :global(.k-pager-md .k-pager-sizes .k-dropdownlist)
{
  width: 30%;
}
