.mb30 {
    margin-bottom: 30px;
}

.actionButton {
    grid-column: 2 / span 2;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    button {
        padding: 7px 13px;
        border-radius: 4px;
        width: 121px;
        border: 1px solid #0059A3;
    }
}

.k-datetimepicker {
    button {
        background-color: white !important;
        border-left: none !important;
    }
}

.reset {
    background-color: white;
    color: #0059A3;
}

.submit {
    background-color: #0059A3;
    color: white;
    margin-left: 12px;
}

.reset:hover {
    background-color: white;
    color: #0059A3;
    border: 1px solid #0059A3;
}

.submit:hover {
    background-color: #0059A3;
    color: white;
    border: 1px solid #0059A3;
}
