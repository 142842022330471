.seller_intent_modal{
  z-index: 1000 !important;
  padding: 10px;
}

.quote_container{
  background: white;
  padding: 0px;
  border-bottom: 1px solid #E6EEF6;
  border-radius:4px;
  color:#465166;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  gap: 20px;
  padding-bottom: 20px;
}

.quote_container_data{
  width: 24% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}

.quote_container_data--label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #9EA7BB;
  margin-bottom: 4px;
}

.quote_container_data--value{
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin-left: 0px;
}

.last{
  border-bottom: none !important;
  padding-bottom: 0px !important;
}

.quote_container_data--button{
  width: fit-content;
  height: 30px;
  padding: 3px 12px 3px 12px;
  border-radius: 4px;
  background: #0059A3;
  color: white;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border: none;
  margin-top: 3px;
}

.quote_container_data--button:hover{
  width: fit-content;
  height: 30px;
  padding: 3px 12px 3px 12px;
  border-radius: 4px;
  background: #0059A3;
  color: white;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border: none;
  margin-top: 3px;
}

.seller_intent_modal_div{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.sim_header{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  color: #465166;
}
.sim_description{
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
}
.sim_input{
  padding-bottom: 20px;
  border-bottom: 2px solid #E6EEF6;
}
.sim_input_radio{
  display: flex;
}
.sim_input_label{
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: var(--fontlineheightsm);
  text-align: left;
  color: #465166;
  margin-left: 5px;
  cursor: pointer;
}
.sim_content{
  display: flex;
  flex-direction: column;
}
.sim_content_header{
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin-bottom: 8px;
}
.sim_content_data{
  display: flex;
}
.sim_content_left_part{
  width: 30%;
}
.sim_content_left_part_data{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #7E8594;
  margin:0px 0px 4px 0px !important;
}
.sim_content_right_part{
  width: 70%;
}
.sim_content_right_part_data{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  color: #7E8594;
  margin:0px 0px 4px 0px !important;
}
.sim_actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.sim_actions_submit{
  background-color: #0059A3;
  color: white;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.sim_actions_submit_disabled{
  background-color: #BEBEBE;
  color: white;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: not-allowed;
}

.sim_actions_cancel{
  color: #0059A3;
  border: 1px solid #0059A3;
  padding: 6px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.sf_intent_change{
  color: #0059A3;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: left;
  margin-left: 5px;
  cursor: pointer;
}
