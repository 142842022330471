.newCarStatusWrapper {
    margin-top: 30px;
    margin-left: 16px;
  }
  
  .addNewNCM{
    margin-top: 15px;
    margin-left: 16px;
    color: #0B6EFD;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .ncmWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .fieldWrapper {
    display: flex;
  }

  .ncmOptionWrapper {
    display: flex;
    align-items: center;
  }

  .radioButton {
    margin: 10px 20px;
  }