@import "variables.scss";


.app-table{
    width: 100%;
    .k-grid{
        border-color:  $tableGridBorderColor !important;
        .k-grid-header{
        border-color:  $tableGridBorderColor !important;
    
        }

        .k-grid-container{
            overflow: unset;
        }
        .k-grid-header-wrap{
        border-color:  $tableGridBorderColor !important;
    
        }
        .k-grid-content{
            padding-bottom: 0px !important;
            overflow: unset;
        }
    }
 .k-table{
    width: 100% !important;

        .k-table-th{
            border-width: 0 !important;
            border-right: 1px solid $tableGridBorderColor !important;
            &:last-child{
                border-right: none !important;
            }

            .k-checkbox{
                width: 16px;
                height: 16px;
            }
        }
        td{
            border-width: 0 !important;
            border-right: 1px solid $tableGridBorderColor !important;
            border-bottom: 1px solid $tableGridBorderColor !important;
            &:last-child{
                border-right: none !important;
            }
        }
        
       tr{
        cursor: default;
       }
    }
    th{
       
        border-width: 1px !important;
        border-style: solid;
        vertical-align: middle !important;

        .k-link{
            
            justify-content: flex-start !important;
        }
        .k-column-title{
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #465166;
            word-break: break-word;
        }
    }
    .k-table-alt-row{
        background-color: #fff;
    }
    .action-cell{
        display: flex;
        flex-flow: column;
        row-gap: 10px;
    }

    .contact-cell:last-child{
        position: relative;
        overflow: unset !important;

    }
    .contacted-cell{
        color: #0ecb0e;
        font-weight: 700;
        font-size: 20px;

    }
    .non-transacting-cell{
        color: #f06767;
        font-weight: 700;
        font-size: 20px;
    }
    .cell-view-all-text{
        display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    } 
    .recommonded-dealer-cell{
        
        .option{
            text-align: left;
            padding-bottom: 10px;

        .reco-dealer-name{
            font-size: 12px;
            font-weight: 400;
            color: #121212;
        }
            

        }
        .k-icon{
            color: #121212;
            width: 14px;
            height: 14px;
            margin-left: 6px;
        }
        .k-i-information{
            cursor: pointer;
        }
    }

    .target-price-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 200px;
        padding: 0px 8px;

        .tp-header{
           display: flex;
           align-items: flex-start;
           flex-direction: column;
           width: 100%;
           gap: 4px;


            .rupee-icon{
                margin: auto;
            }

        }

        .tp-text{
            color: $titleColor;
            word-break: break-word;
        }

        .tp-submit{
            width: 100%;
            border: 1px solid $primaryColor;
            background-color: white;

            .k-button-text{
                color: $primaryColor;
            }

            &:hover{
                background-color: $primaryColor;
                
                .k-button-text{
                    color: white;
                }
            }
            
        }
    }


    .k-pager{
        .k-pager-numbers-wrap{
            justify-content: flex-end;
            flex: 1.5;
            .k-button{
                border: 1px solid #D5E1ED !important;
                border-right: 0 !important;
                &:last-child{
                    border-right: 1px solid #D5E1ED !important;
                }
            }
            .k-disabled{
                background-color: #f5f5f5  !important;
                .k-button-icon{
                    color:gray !important;
                }
            }
           
        }
        .k-selected{
            border-radius: 0;
            background: #F8FBFF !important;
        }
        .k-button-icon{
            color: #0059A3;
        }
        .k-button-flat-primary {
            color: #0059A3;
        }
        .k-pager-info{
            font-size: 16px;
            color: #465166;
        }
    }
    .k-checkbox:checked, .k-checkbox.k-checked{
        border-color: #EF6E0B;
        color: white;
        background-color: #EF6E0B;
    }
    .k-checkbox:focus, .k-checkbox.k-focus{
        outline: 0;
         box-shadow: none;
    }

    .yard-details{
        word-break: break-word;
    }

    .table-checkbox{

        &:nth-child(2){
            border-right:none !important
        }

        .k-checkbox{
            width: 16px;
            height: 16px;
        }
    }
    
}

.tl-view-header{
    @extend .app-table;
    
    
    .k-table{

      
        .k-table-th{

            .k-checkbox{
                width: 20px;
                height: 20px;
            }
    
            
            &:nth-child(2){
                border-right: none !important;
            }

            &:nth-child(3){
                padding-inline: 0px !important;
            }
        }

    }


    .table-checkbox{

        &:nth-child(2){
            border-right:none !important
        }

        .k-checkbox{
            width: 20px;
            height: 20px;
        }
    }
}

.app-table-view-sm{
    th{
        .k-link{
            padding: 8px 4px !important;
            .k-column-title{
                font-size: 14px !important;
                word-break: break-word;

            }
        }
    } 
}
.app-table-hide-header{
    
    .k-grid .k-grid-header{
        display: none;
    }
    .highlight-label{
        background-color: #F8FBFF;
    }
    .k-table{
            td{
               &:last-child{
                    border-right:1px solid $tableGridBorderColor !important;
                }
            }
            
           
        }
}

.app-expandable-table{
    width: fit-content;

    .k-table{
        width: 100% !important;
    }


    .k-svg-i-plus,
    .k-svg-i-minus{
        display: block !important;
    }
    .k-table-row{
        &:hover{
            background-color:transparent !important;
        }
            .k-hierarchy-cell{
                border-right-width: 0px !important;
            }
            .k-detail-cell{
                    border-left-width: 0px !important;
        }
    }
    .k-hierarchy-cell{
        a{
            color: #ed700f;
        }
    }

    .contact-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;

        .call-button{

            .k-button-text{
                display: flex;
                align-items: center;
            }
            
            padding: 9px 12px;
            background-color: #ed700f;
            color: white;            
            border: none;
            
    
            .call-text{
                font-size: 16px;
                margin-left: 4px;
            }
        }
    }
   
}
.cell-remove-hover{

}
.grid-detailed-view{
    
}
.k-table{
    .k-table-thead{
        background-color: #F3F3F3;   
    }

    
}
.multiline-cell{
    white-space:pre !important;
}

.multiselect-enabled-table{
    table td:first-child, table th:first-child{
        text-overflow: clip !important;
    }
}