.header {
  display: flex;
  justify-content:space-between;
 
}
.header span {
  font-size: large;
  font-weight: 700;
}
.options {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.pageSizeSelector {
  width: 60px;
  height: 38px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding-left: 5px;
  border: 1px solid #ced4da;
}
.exportButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-right: 5px;
}
.exportButton img {
  cursor: pointer;
}
.exportButton button {
  border-radius: 0 !important;
  background-color: #f6f6f6;
  border-color: rgba(0, 0, 0, 0.08);
}
