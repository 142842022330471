.table-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  
  .table-content{
    display: flex;
    white-space: nowrap;
    gap: 30px;
  }
  .table-text{
    font-weight: 500; 
    color: #465166;
  }
  

  .k-textbox{
    display: flex;
    align-items: center;
    border: none;
    border-radius:0; 
    background-color: #F3F3F3;
    padding:0; 
    border-bottom: 2px solid #153E6E;

  .k-input-inner{
     padding: 2px 4px 
  }

  .search-icon{
    color: #153E6E;
  }

}
.k-input-solid:focus-within{
  background-color: #F3F3F3;
  box-shadow: none;
  border-color: #153E6E;
  
}
}

.conversation-container{
  display: flex;
  align-items: center;
  gap: 5px;
  
  .table-text{
    font-weight: 500; 
    color: #465166;
    width: 95%;
  }

  .sorting-icon{
    cursor: pointer;
  }
}   





   
