@import "variables.scss";


.car-card-container {
    display: flex;
    column-gap: 8px;

        .card-details {
        width: 100%;
        row-gap: 10px;
        display: flex;
        flex-flow: column;

        .card-heading{
            flex-wrap: wrap;
            gap: 15px;
        }

        .card-title {
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;
            color: $titleColor;
            text-align: left;
            width: 60%;
            word-break: break-word;
            width: 60%;
            
            .car-details-tooltip{
                display: inline-block;
                vertical-align: text-bottom;
                margin-left: 4px;
            }
            
        }

        .card-sub-title {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: $titleColor;
        }
        
        .hours-left {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: $hoursLeftIndicatorColor;
            white-space: nowrap;
        }

        .card-highlights {
            display: flex;
            align-items: center;
            column-gap: 10px;
            row-gap: 10px;
            width: 100%;
            flex-wrap: wrap;

            .highlight-chip {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $highlightBgColor;
                color: $highlightTextColor;
                padding: 4px 8px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 14px;
                font-weight: 600;
                word-wrap: break-word;
            }

            .car-sub-title{
                font-size: 12px;
                line-height: 20px;
                color: $secondaryTitleColor;
            }


        }

        .hr-line {
            color: $hrColor;
            margin: 0px;
        }

        .detail-bid-block {
            background-color: $cardHighlightBgColor;
            padding: 4px;
            border-radius: 4px;

            .left-block,
            .right-block {
                display: flex;
                flex-flow: column;
                text-align: left;
            }

            .card-label {
                margin: 0;
                color: $cardLabelColor;
                font-size: 12px;
                font-weight: 400;
            }

            .card-value {
                margin: 0;
                color: $cardValueColor;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                width: fit-content;
                word-break: break-word;
            }
        }

        .pdf-icon {
            color: $primaryColor;
            margin-left: 4px;
            cursor: pointer;
        }
    }
    .car-tag-indicator {
        display: flex;
        align-items: center;
        gap: 4px;
        border-radius: 8px 1px 8px 1px;
        padding: 4px 6px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        .car-shared-indicator {
            background-color: #6938EF;
        }

        
    
        .indicator-text {
            font-weight: 600;
            font-size: 12px;
            color: #fff;
        }
    
        &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 2px;
            transform: rotate(225deg);
            border-width: 6px; 
            border-style: solid;
            border-color:  $secondaryBtnTextColor transparent transparent transparent;
        }
    }
    
.card-image-container{
    position: relative;


    .car-status{
        position: absolute;
        z-index: 10;
        background-color: green;
        color: white;
        padding: 5px;
        font-size: 12px;
        border-bottom-right-radius: 10px;
        display: flex;
        justify-content: center;
    }

    .nudge{
        position:relative;
        top: -20px;
        background-color: #f5f5f5;
        z-index: 1;
        padding: 5px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px; 
        width: 230px;

        div{
          font-size: 10px;
          word-break: break-word;
        }
       
  
    } 
    
  

    .car-card-img{
        position: relative;
        width: 230px;
        height: 170px;
        max-height: 170px;
        max-width: 230px;
        cursor: pointer;
        height: auto;
        border-radius: 8px;

    }

}


    
     
        
    

}
.card-auction-ended{
    background: #ffdcdc33;
    .detail-bid-block{
        background: transparent !important;
    }
}

tr:has(> td > div.card-auction-ended) {
    background: #ffdcdc33 !important;
}