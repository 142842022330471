@import '../../../common/assets/styles/variables.scss';

.quality-modal-container{
  position: absolute;
  top: 50px;
  left: -200px;
  width: 275px;
  background-color: white;
  z-index: 1;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 4px 4px 8px #00000014;

  .modal-header{
    width: 100%;
    display: flex;
    flex-direction: column;

    .title-text{
      font-weight:700;
      color: $modalTitleColor;
      word-break: break-word;
    }
    
    .gauge-container{
      margin: 16px;

      .k-arcgauge-label{
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        h3{
          color: #595C5F !important;
          font-size: 18px;

        }
      }

    }

  }

  .modal-body{
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);


    .title-text{
      font-size: 12px;
      word-break: break-word;
    }

    .info-text{
      font-weight:700;
      font-size: 10px;
      color: $modalTitleColor;

    
    }

    .red{
      color: #D92D22;
    }

    .yellow{
      color: #FFB800
    }

    .green{
      color: #0A7A46;
    }

    .info-container{
      padding: 8px;
      border-bottom: 1px solid #C7C8C9;   
    }


      .k-button-solid-base{
        background-color: #0A6FFE1A;
        border-color: #0A6FFE4D;
        width: min-content;
        align-self: center;
        margin-top: 8px;

        .k-button-text{
          color: #0A6FFE;
          font-weight: 700;
        }
      }
    
  }
}