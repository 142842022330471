.head {
    width: 100%;
    min-height: 100px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.head_aoi{
  width: calc(100vw - 430px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  overflow: scroll;
  padding-bottom: 20px;
}

.aoeData{
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 4px 0px #00000014;
  border: 0.4px solid #C7C8C9;
  border-radius: 8px;
  padding: 12px;
  min-width: 240px;
  gap: 12px;
}

.aoeData_header{
  display: flex;
  min-width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.aoeData_header_parameter{
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.96px;
  color: #212529;
}

.aoeData_header_score{
  border: 1px solid ;
  border-radius: 16px;
  padding: 0px 10px;
  font-size: 12px;
  text-align: center;
}

.aoeData_content_container{
  display: flex;
  flex-direction: column;
}

.aoeData_content{
  padding: 12px;
  border-top: 0.4px solid #C7C8C9;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.aoeData_content_heading{
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.96px;  
  color:#3B8CFE;
}

.aoeData_content_detail{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.96px;  
}

.head_collections--error{
  font-size: 14px;
  font-weight: 550;
}