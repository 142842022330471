.head {
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    min-height: 100px;
}

.head_time {
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    position: relative;
}

.head_time:hover {
    text-decoration: underline;
}

.head_time::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #003e72;
    top: 7px;
    border-radius: 50%;
    left: -17px;
}

.head_summary {
    width: 100%;
    text-align: left;
}

.head_summary * {
    margin: 0px !important;
}

.head_qna {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.head_qna--title {
    margin: 0px !important;
    font-size: 14px;
    font-weight: 550;
    color: #003e72;
    cursor: pointer;
}

.head_qna--title:hover {
    text-decoration: underline;
}

.head_qna_collection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 25px;
    max-height: 0px;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.head_qna_collection--expanded {
    max-height: 99999px;
}

.head_qna_content {
    display: flex;
    flex-direction: column;
    gap: 3px;
    position: relative;
}

.head_qna_content::before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #003e72;
    top: 7px;
    border-radius: 50%;
    left: -17px;
}

.head_qna_content--question {
    margin: 0px !important;
}

.head_qna_content--answer {
    margin: 0px !important;
}

.callLogDetails_value{
  font-weight: 700;
  color: #003562 !important;
}