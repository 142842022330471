@import "../../assets/styles/variables.scss";
.tab-unlock-ra-details-container {
    .tab-title-btn-block {

        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .tab-title {

            font-size: 16px;
            font-weight: 400;
            line-height: 18.75px;
            color: $titleColor;

        }
        .btn-icon {

            padding-right: 4px;
            
        }
    }
}

.loader-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ra-contact-grid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .contact-icon-button{
        border: none;
        background-color: transparent;
    }
}