@import '../../../assets/styles/variables.scss';


.call-modal-container{
  position: absolute;
  top: 50px;
  padding: 20px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  right: 12px;
  z-index: 99;
  width: 300px;
  background-color: white;

.call-modal-header{
  display: flex;
  flex-direction: column;


  .title-color{
    color:$modalTitleColor;
    font-weight: 500;
  }

  .call-modal-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    
      .modify-button{
        color: $primaryColor;
        padding: 0;
        font-weight: 500;
      }

      .k-button:focus {
        box-shadow: none;
       }

    }

    
    .contact-header{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .contact-container{
      display: flex;
      flex-direction: column;

    
      .contact-status{
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 12px;
        color: $cardLabelColor;
      }

      .confirmation-action-container{
        display: flex;
        align-items: center;
        gap: 10px;

        .confirmation-message{
          word-break: break-word;
          width: 80%;
        }
      }
      
      .contact-info{
       display: flex;
       gap: 10px;
       align-items: center;

       .contact-details{
          display: flex;
          flex-direction: column;

          .contact-number{
            font-size: 16px;
            font-weight: 500;
          }
          
       

          .default-text {
            display: flex;
            padding: 0;
            justify-content: flex-start;
            color: $cardLabelColor;
            font-size: 12px;
            word-break: break-word;
            
            .k-button-text{    
              font-size: 12px;

              &:hover{
                color:$primaryColor;
              }

            &:focus{
              box-shadow: none;
            }
            }

         

          }
         
       }
      }

   

    }

    .edit-container{
      display: flex;
      align-items: center;
      gap: 15px;
    }

    .modal-call-icon-button{
      border: none;
      background-color: transparent;
    }

    }

    .add-number-container{
      display: flex;
      align-items: center;
    }

    .modify-info-message{
      color: $cardLabelColor;
    }
   
}
}

