
.quote_container{
  background: white;
  padding: 20px 12px 20px 12px;
  border-bottom: 1px solid #E6EEF6;
  border-radius:4px;
  color:#465166;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
}

.quote_container_data{
  width: 24%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}

.quote_container_data--label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #9EA7BB;
  margin-bottom: 4px;
}

.quote_container_data--value, .quote_container_data--value :global(span){
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin-left: 0px;
}

.quote_container_data--button{
  width: fit-content;
  height: 38px;
  padding: 7px 13px 7px 13px;
  border-radius: 4px;
  background: #0059A3;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.get_more_price_container{
  display: flex;
  flex-direction: column;
}

.edit_icon{
  color: #0059A3;
  margin-left: 5px;
  margin-top: -3px;
  cursor: pointer;
}

.quote_container_data--secondaryNumber{
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #465166;
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
}

.last{
  border-bottom: none !important;
}

.secondaryButton--inputSpan{
  margin: 5px 0;
}

.secondaryButton--input{
  width: 180px;
}

.secondaryButton--submit{
  margin: 0;
}

.secondaryButton--cancel{
  margin: 0;
}

.secondaryButton--data{

}

.secondaryButton--addNew{
  color: #0059A3 !important;
  margin: 0;
  padding: 0px;
}

.secondaryButton--addNew--icon{
  margin-right: 5px;
}

.secondaryButton--addNew:hover{
  background-color: white !important;
}

.secondaryButton--addNew::before:hover{
  background-color: white !important;
}

.quote_container_data--callBtn{
  background: white !important;
  color: #0059A3;
  border: 1px solid #0059A3;
  margin: 5px 10px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.call_icon{
  margin-left: 5px;
  cursor: pointer;
}