@import "../common/assets/styles/variables.scss";

.calendar-container{
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: relative;
  border-radius: 10px;



  .calendar-header{
    padding: 12px 24px;
    background-color: $cardHighlightBgColor;
    border:1px solid $borderColor;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;    
    border-bottom: none;


    .selected-date-text{
      font-weight: 600;
      font-size: 20px;
    }
  }
  
 
  .calendar-body{

    background-color: $cardHighlightBgColor;

    .k-calendar-table{
      table-layout: inherit;
      width: 100%;
      background-color: $cardHighlightBgColor;
    }

    .k-calendar-view{
      padding:0;
      margin: 12px;
    }

      .k-button-text{
        color: $primaryColor;
        font-weight: 500;
      }
  
      .k-calendar-yearview {
        .k-link {
          border-radius: 0%;
          font-weight: 500;
      
          &:hover {
            background-color: $primaryColor;
            color: white;
          }
        }
  
        .k-selected .k-link{
          border-radius: 0%;
      
        }
        .k-range-mid{
          background-color: $cardHighlightBgColor;
        }
      }
        
  
    .k-range-start{
      background-color: $appBodyBgColor;
    }
  
    .k-range-end{
      background-color: $appBodyBgColor;
    }
    
    .k-range-mid{
      background-color: $appBodyBgColor;
    }
    .k-selected .k-link{
      border-radius: 100%;
      background-color: $primaryColor !important;
      box-shadow: none !important;
  
    }
    .k-link{
      border-radius: 100%;
      box-shadow: none !important;
      
    }
  
  
  }
  


  .calendar-footer{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    bottom: 0;
    position: absolute;
    z-index: 999 !important;
    gap: 16px;
    padding: 12px;


    .apply-button{
      background-color: $primaryColor;
      color: white;
      font-weight: 500;
      padding: 8px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
    }

    .cancel-button{
      background-color: $appBodyBgColor;
      color: $primaryColor;
      font-weight: 500;
      border: none;
      padding: 8px 20px;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;
          
    }

  }

  .k-calendar {
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}




.call-log-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  
  .search-container{
      display: flex;
      align-items: center;
      gap: 15px;
      width: 50%;
      
      .call-log-text{
        font-size: 16px;
        color: $titleColor;
        font-weight: 500;
        white-space: nowrap;
      }
  }
  

  .text-box-container{
    width: 50%;

    .k-textbox {
      display: flex;
      align-items: center;
      }
  }

}

 .date-dropdown-container{
  display: flex;
  flex-direction: column;
  width: 10%;
  

  .k-picker-solid,.k-picker-solid:focus-within{
    border: 0.5px solid black;
    background-color: white;

    &:hover{
      border-color: $primaryColor;
    }
  }

  .k-input-button{
   background-color: white;
  }

  .date-container{
    position: absolute;
    top: 80px;
    right: 10px;
    z-index: 1;

   }
  

 }



.call-log-table{
 
    .k-grid {
      
        border-color: $tableGridBorderColor !important;

        .k-grid-container{
          overflow: unset !important;
        }

        .k-grid-header {
            border-color: $tableGridBorderColor !important;

        }

        .k-grid-header-wrap {
            border-color: $tableGridBorderColor !important;

        }

        .k-grid-content {
           overflow: auto;
            padding-bottom: 0px !important;
        }

        .k-table-th{
          text-align: center;
        }

        .k-table-td  {
          color: $secondaryTitleColor;
          
          > span {
            font-weight: 500;
          }

          
        }

        .k-grid-content{
          overflow: unset;
          

        }
        
    }

    .k-table-td{
      padding: 8px !important;
    }


    .table-text{
      word-break: break-word;
    }


}

.quality-container{
  overflow: unset !important;
    .call-status-container {
      justify-content: center;
    }

}

.score-modal-container{

  .k-dialog{
    width: 55%;
  }

  .k-window-content{
    width: 100%;
  }
}


