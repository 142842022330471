.shareLinkContainer {
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: relative;
}

.shareLinkContainer :global(*) {
  flex: none;
}

.shareCounter {
  border-radius: 50% !important;
  width: 28px;
  height: 28px;
  display: grid;
  place-items: center;
  background-color: #e3e3e3 !important;
  font-weight: 700;
  font-size: 13px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.shareLoader {
  width: 20px;
  height: 20px;
  border: 3px solid #0059A3;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}