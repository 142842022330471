
.contact-container{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .call-button{

      .k-button-text{
          display: flex;
          align-items: center;
      }
      
      padding: 9px 12px;
      background-color: #ed700f;
      color: white;            
      border: none;
      

      .call-text{
          font-size: 16px;
          margin-left: 4px;
      }
  }
  
  .contact-icon-button{
    border: none;
    background-color: transparent;
  }

  .no-contact-button{
    cursor: not-allowed;
    background-color: rgb(211, 206, 206) !important;
  }


}
