.head{
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_data{
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 350px;
  overflow-y: scroll;
}

.head_data_content{
  width: 80%;
  max-width: fit-content;
  background: #0059A3 !important;
  color: white;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 10px;
}
.head_data_content :global(p){
  margin: 0px !important;
}

.head_data_IN{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.head_data_OUT{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.timestamp{
  font-size: 9px;
  text-align: right;
}

.head_collections--error{
  font-weight: 550;
  font-size: 14px;
  margin: 0 !important;
}

.loadMore{
  background: white !important;
  color: #0059A3 !important;
  margin: 5px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #0059A3;
  width: fit-content;
  align-items: center;
  margin: auto;
}
