@import "../common/assets/styles/variables.scss";

.side-bar-filters {
    position: fixed;
    top: 0;
    z-index: 999;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: transparent;

    .overlay {
        z-index: 2;
        background-color: $filterOverlayBgColor;
        opacity: 0.3;
        height: 100vh;
        width: 100vw;
    }

    .filters-block {
        width: 30%;
        max-width: 30%;
        background-color: $filterBgColor;
        height: 100vh;
        z-index: 3;
        position: absolute;
        right: 0;
        top: 0;
        padding: 16px;
        display: flex;
        flex-flow: column;
        .app-multi-select {
            max-width: 434px;
        }
        .close-icon {
            color: $filterCloseIconColor;
            font-size: 22px;
            margin: 0;
        }

        .search-input{
            margin: 10px 0;
            padding: 8px;
        }

        .checkbox-title{
            display: flex;
            font-size: 16px;
            font-weight: 500;
            align-items: center;
            gap: 5px;
        }

        .k-expander-content{
            padding: 0;
        }
    

            .cities-container{
                margin-left: 20px;
                padding: 5px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
       

            .center-checkbox{
                padding: 5px;
            }
            
            .checkbox-label{
                display: flex;
                align-items: center;
            }
           
        

        .center-text{
            padding: 2px;
            font-size: 16px;
            font-weight: 500;
        }

        .filter-title {
            color: $titleColor;
            font-size: 19px;
            align-self: flex-start;
            padding: 16px 0;
        }

        .center-filter{
            max-height: 150px;
            overflow: scroll;

            .k-expander.k-focus{
                box-shadow: none;
            }
        }


    }
    
    
}

.app-sidebar-filter {
    z-index: 10003 !important;
}

.app-expansion-panel {
    border-color: $appExpansionBorderColor;
    background: $appExpansionBgColor;
    color: $appExpansionColor;
    font-size: 14px;
    padding: 8px;
    border-width: 0px 0px 1px 0px;

    .k-expander-header {
        height: auto;
        padding: 8px;

        &:hover {
            background: #f8fbff;
        }

        .k-expander-title {
            color: $titleColor;
        }
    }
}

.app-expansion-panel + .app-expansion-panel.k-expanded,
.app-expansion-panel.k-expanded + .app-expansion-panel {
    margin-top: 0;
}

.app-expansion-panel.k-focus {
    box-shadow: none;
}

.actions-block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 16px;
    width: calc(100% - 32px);
    .match-filters {
        font-size: 14px;
        font-weight: 700;
        line-height: 18.62px;
        text-align: left;
        color: $linkBtnColor;
    }
    .btn-block {
        display: flex;
        column-gap: 12px;
    }
}

.filter-range-slider {
    width: 100%;
}
.k-slider {
    margin-bottom: 20px;
    padding: 0 10px;
    width: 100%;
    padding: 20px;
}
.k-label {
    font-size: 14px;
}

.k-slider-horizontal .k-label {
    top: 25px;
}

.side-bar-filters {
    .k-slider .k-draghandle {
        border-color: $primaryColor;
        background-color: $primaryColor;
        &:focus {
            box-shadow: none;
        }
    }
    .k-slider .k-slider-selection {
        background-color: $primaryColor;
    }
}

.filters-applied-icon {
    background-color: $primaryColor;
    .k-icon {
        color: #fff;
        &:hover {
            color: #fff;
        }
    }
}
