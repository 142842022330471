.head{
  display: flex;
  flex-direction: column;
  z-index: 10000 !important;
}

.head :global(.k-window-title){
  font-weight: 700;
}

.head :global(.k-window-titlebar), .head :global(.k-dialog-titlebar){
  border-bottom: 1px solid #dee2e6;
}

.head_content{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.head_reason_label{
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.head_reason_dropdown{

}

.submitHandler{ 
  border: 0;
  padding: 10px 20px;
  width: fit-content;
  background: #0059a3 !important;
  color: #fff;
  border-radius: 4px;
  margin-left: auto;
}

.options_dropdown{
  z-index: 9999999 !important;
}