@import "../common/assets/styles/variables.scss";

.all-cars {
    .title-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        gap: 16px;

        .title-text {
            font-size: 19px;
            font-weight: 400;
            line-height: 25.27px;
            color: $titleColor;
            white-space: nowrap;
        }

        .tl-view{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
          
          }

        .view-all-pais {
            display: flex;
            align-items: center;
            gap: 10px;
     
        }
    }

    .ongoing-car-cards {
        column-gap: 24px;
        box-shadow: 0px 2px 4px 0px $ongoingCardsBoxShadowColor;
        padding: 16px;
        margin-bottom: 24px;
    }

    .recommended-cars {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $secondaryTitleColor;
    }

    .all-cars-table {
        width: 100%;
        background: $allCarsTableBgColor;
    }

}


.all-cars-filters {
    display: flex;
    align-items: center;
    flex-flow: row;
    column-gap: 10px;

    .dropdown-text{
        margin-left: 5px;
        font-weight: 500;
        white-space: nowrap;
    }

    .dropdown-disabled{
        color: $dropdownBorderColor;
    }

    .app-dropdown-container {
        cursor: pointer;
        display: flex;
        white-space: nowrap;
        align-items: center;
        border: 1px solid $dropdownBorderColor;
        border-radius: 4px;
        padding: 4px;


        &:hover {
          border: 1px solid $dropdownHoverBorderColor;
        }
      }

      
      .app-dropdown-container.disabled {
        cursor: not-allowed;
        pointer-events: none;
        border: 1px solid $dropdownBorderColor;
      
        &:hover {
          border: 1px solid $dropdownBorderColor; 
          
        }
      }
    

}


.nested-dropdown{
    width: 200px !important;
    
    .k-treeview{
        padding: 0;
    }

    .k-treeview-item{
        padding: 0;
    
        .k-treeview-leaf{
            width: 100%;
            box-shadow: none;
            padding:8px;

            &:hover{
                color: black;
                background-color: $dropdownListHoverBgColor;
                cursor: pointer;
            }

            &:focus{
                box-shadow: none;
            }

        }

        .k-treeview-leaf.k-selected{
            background-color: $dropdownListSelectedBgColor;

            &:hover{
                color: black;
                background-color: $dropdownListHoverBgColor;
                cursor: pointer;
            }
        }

        .k-treeview-bot{
            position: relative;
   
        }

        .k-treeview-toggle{
            position: absolute;
            right: 0;
            z-index: 100;
            padding: 8px;

          
        }
    }
    

  
  }

    .filter-dropdown{
        width: 200px;
        
        .k-clear-value{
            display: none;
        }
    }


.all-cars-table {
    .k-table {
        .k-table-th {
            border-width: 0 !important;
            border-right: 1px solid $tableGridBorderColor !important;

            &:last-child {
                border-right: none !important;
            }
        }

        td {
            border-width: 0 !important;
            border-right: 1px solid $tableGridBorderColor !important;
            border-bottom: 1px solid $tableGridBorderColor !important;

            &:last-child {
                border-right: none !important;
                // border-bottom: none !important;
            }
        }


    }

    .k-grid {
        border-color: $tableGridBorderColor !important;

        .k-grid-header {
            border-color: $tableGridBorderColor !important;

        }

        .k-grid-header-wrap {
            border-color: $tableGridBorderColor !important;

        }

        .k-grid-content {
            padding-bottom: 0px !important;
        }
    }
}

.recommended-filter {
    min-width: 186px;
}

.share-car-btn-block{
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: #fff;
    padding: 16px;
    border-top: 1px solid #E6EEF6;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
}
.share-car-slider-block{
    max-width: 45%;
    left: 55%;
    text-align: center;
    justify-content: center;
    
}
.cars-selected-count{
    padding-left: 10px;
    color: #ef6e0b;
}