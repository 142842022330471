.head{
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head_data{
  width: 100%;
}

.markdown_p{
  margin: 0px !important;
  margin-bottom: 2px !important;
}

.head_collections--error{
  font-size: 14px;
  font-weight: 550;
}