@import '../../common/assets/styles/variables.scss';

.right-container{
  padding: 28px 24px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  width: 60%;

  .control-center-table{
    border:none;
    max-height: 75vh;

    .k-table-th{
      border-right: none;
    }
    .k-grid-pager{
      border: none;
    }

  }

  .loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .k-loader-canvas{
      color: $primaryColor;
    }
  }

}