.detailsTab {
  width: 100%;
  padding: 1rem;
}

.detailsTab :global(.k-item) {
  background: linear-gradient(90deg, #e2e7ec 0%, #ebf0f5 23%);
  border-color: #788791 !important;
  color: #444953 !important;
}

.detailsTab :global(.k-active) {
  background: white;
  border-color: #788791 !important;
}

.backBtn {
  justify-self: start;
  margin-left: 14px;
  margin-top: 14px;
}

.backBtn :global(.k-button-text) {
  font-size: 1.2rem !important;
}

.audioPlayerContainer {
  text-align: center !important;
}