
.modalContent{
  display: flex;
  flex-direction: column;
}

.form{
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.form-wrapper{
  margin: 0px !important;
  text-align: left;
}

.form-wrapper :global(.k-picker-solid){
  background-color:white;
}

.form-wrapper :global(.k-picker-solid:focus-within){
  background-color: white;
}

.form-icon-label{
  display: flex;
  align-items: center;
  gap: 4px;
}

.form-label{
  font-weight: 700;
  margin: 4px 0px;
}

.form-label-required::after {
  content: "*";
  margin: 0 2px;
  color: red;
}
.form-button{
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.submitButton{
  color: white;
  background-color: #003e72;
  &:hover{
    background-color: #002b4c;
  }
}

.chipContainer {
  display: flex;
  margin-top: 10px;
}

.entity-hint{
  display: flex;
  justify-content: space-between;
}

.clear-entity-btn:global(.k-button .k-button-text){
  color: #003e72;
  font-size: 12px;
}
