.nego_header{
  position: sticky;
  top: 72px;
  z-index: 998;
  background: white;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px 10px;
  border-bottom: 1px solid #E6EEF6;
}

.nego_breadcrumb{
  display: flex;
  gap: 5px;
  align-items: center;
}

.nego_breadcrumb__prevLink{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;  
  color: #737373;
  cursor: pointer;
  text-decoration: none !important;
}
.nego_breadcrumb__prevLink:hover{
  text-decoration: underline !important;
}

.nego_breadcrumb__seperator{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;  
  color: #737373; 
}

.nego_breadcrumb__currLink{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  color: #424242;
}

.nego_header_tags{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border: 1px solid transparent;
  min-width: 300px;
}

.nego_header_tags:hover,.nego_header_tags_acitve{
  border: 1px solid #E6EEF6;
  border-radius: 4px;
  cursor: pointer;
}

.nego_header_tags_data{
  position: relative;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-align: center;
  padding: 5px 16px;
  border-radius: 16px;
  cursor: pointer;
}

.nego_header_tags_add{
  color: #0059A3 !important;
  cursor: pointer;
}

.nego_header_tags_add:hover{
  text-decoration: underline;
}

.nego_header_dropdown_div{
  position: absolute;
  width: 100%;
  background: white;
  top: 55px;
  left: 0;
  border: 1px solid #E6EEF6;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 0px 4px 5px 0px #0000000A;
}

.nego_header_dropdown_div_currSelected{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px;
  border-bottom: 1px solid #E6EEF6;
  background: #E6EEF633;
}

.nego_header_dropdown_div_optionsDiv{
  padding: 8px;
  border-bottom: 1px solid #E6EEF6
}

.nego_header_dropdown_div_optionsHeader{
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #737373;
}

.nego_header_dropdown_div_otions{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nego_header_tags_data_listing{
  flex:unset;
  width: fit-content;
}

.nego_header_tags_data_cross{
  position: relative;
  right: -5px;
  font-size: 13px;
  top: 0px;
}

.nego_header_dropdown_actions{
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nego_header_dropdown_actions_save{
  background: #0059A3 !important;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  margin-right: 10px;
}

.nego_header_dropdown_actions_close{
  background: white !important;
  color: #0059A3;
  border: 1px solid #0059A3;
  padding: 5px 15px;
  border-radius: 4px;
}

.nego_header_tags_container{
  position: relative;
}

.nego_header_tags_data :global(.k-icon.k-i-close){
  margin: 0;
}

.additional_info{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.mmvy_div{
  display: flex;
}

.mmvy_div_label{
  margin: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #737373;
}
.mmvy_div_name{
  margin: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #424242;
}
.priority_div{
  display: flex;
}
.priority_div_label{
  margin: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #737373;
}
.priority_div_name{
  margin: 0;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #424242;
}

.nego_header_dropdown_div_options{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.nego_header_dropdown_div_categoryHeader{
  margin: 0px 0px 5px;
}