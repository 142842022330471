@import "../../assets/styles/variables.scss";

.tab-reco-details {
    
    .highlight-label {
        vertical-align: auto;
        .grid-label {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: $planeGridLabelColor;
            word-break: break-word;
        }
    }

    .contact-grid-value {
        display: flex;
        flex-direction: column;
       
        .grid-text{
            color: $titleColor;
            font-weight: 500;
        }
        
    }

    .title-bar{
        display: flex;
        align-items: center;
        
        margin: 10px 0px;
        gap: 6px;
        .title-text{
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #424242;
        }

        .k-checkbox-wrap{
            display: flex;
            align-items: center;
            align-self: normal;
        }
      
    }
    
    .app-search-input{
        width: 40%;
        margin-top: 16px;
    }

    .reco-dealer-title{
        display: flex;
        align-items: center;
        gap: 10px;

        .tab-title-text{
            font-weight: 500;
            color: $modalTitleColor;
        }
    }

    .k-tabstrip-items-wrapper{
        .k-item.k-active{
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $highlightTextColor !important;
            
            }

    }
    

    .reco-table-wrapper{

        .k-checkbox{
           width: 15px;
           height: 15px;
        }
        .k-checkbox:checked{
            border-color:$highlightTextColor ;
            background-color: $highlightTextColor;
    
    
        }

      
        .k-table-row.k-selected > td
        {
            background-color: $allCarsTableBgColor !important;
        }
        
        
       
    }
    

    .table-as-grid .k-grid td:first-child{
        background-color: #F8FBFF;
        border-right: none !important;
        vertical-align: middle;
        text-align: center;
        padding-right: 0px !important;
        
    }

     .table-as-grid .k-grid {
        margin-bottom: 50px;
    }
    
}

.contact-cell{
    width: 100%;
}

.contact-cell:last-child{
    position: relative;
    overflow: unset !important;
    vertical-align: middle !important;
}




.dealer-car-shared-cell{
    padding: 7px 16px 7px 16px;
    gap: 8px;
    border-radius: 18px;
    background: #198754;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: center;
    color: #fff;
    max-width: 160px;
    margin-top: 10px;
    word-break: break-word;
}

.ra-contact-cell{
    display: flex;
    justify-content: flex-end;
    
}