@import '../../common/assets/styles/variables.scss';

.paConfirmationModal{
  
  .k-dialog{
    width: 400px;
    padding: 12px;
  }
  
  .k-dialog-content{
    width: 100%;

  }

  .confirmation-text{
    font-size: 20px;
    font-weight: 500;
    font-size: 20px;
    word-break: break-word;
    line-height: 28px;

    .highlight-email{
      @extend .confirmation-text;
      color: $primaryColor;
    }

  }

  .k-actions{
  
    .k-button-solid-base{
      max-width: 45% !important;
  
    }

   .cancel-btn{

      border: 1px solid $primaryColor;
      color:$primaryColor

   }

   .confirm-btn{
    color: white;
    background-color: $primaryColor;
    border-color: $primaryColor;
   }

  }

}
