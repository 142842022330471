.label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #465166;
    margin-bottom: 4px;
    
    span {
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

}