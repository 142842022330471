.bgGreen {
  background-color: #a4ffd5a9;
  color: #00BA67;
  font-weight: 500 !important;
}

.bgYellow {
  background-color: #f69c0046;
  color: #F69B00;
  font-weight: 500 !important;
}

.bgRed {
  background-color: #EB575733;
  color: #EB5757;
  font-weight: 500 !important;
}

.gridLoaderContainer{
  position: relative;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.loader {
  position: absolute;
  z-index: 1000;
  border: 7px solid #f3f3f3;
  border-top: 7px solid #3498db;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.filtersContainer {
  position: absolute;
  top: 8px;
  right: 1%;
  gap: 12px;
}

.tableFilterContainer {
  border: 1px solid #d4d4d4 !important;
  border-radius: 6px;
  align-items: center;
  padding-right: 6px;
  overflow: hidden;
}

.tableFilterDropdown {
  max-width: 150px;
  background: #ebf5ff !important;
  border: none;
}

.tableFilterDropdown :global(.k-input-button) {
  border-left-width: 0;
  border-right-width: 1px;
}

.tableFilterDropdown :global(.k-clear-value) {
  display: none;
}

.tableFilterInput{
  border: none;
  padding: 0;
}

.tableFilterSearchIcon{
  max-width: 1.45rem;
  max-height: 1.45rem;
}

.adminFilterPosition {
  right: 20% !important;
}