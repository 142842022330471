.app-chips{
  max-height: 25vh;
  overflow-y:scroll ;
}
.entity-id-popup{
  z-index: 999 !important;
}

.entity-id-popup :global(.k-list-item){
  box-shadow: none;
}