.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  max-height: 80%;
  min-height: 45%;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.modalBody :global(.k-column-title) {
  width: 100%;
  display: flex;
  justify-content: center;
}
