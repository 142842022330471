@import "variables.scss";
.app-tabs{
    .k-content{
        border:none;
        padding: 0;
    }
    .k-tabstrip-items-wrapper{
        background: $tabItemBgColor;
        border-color: $tabItemBorderColor;
        .k-item{
            color: $tabItemColor;
        }
        .k-active{
            background-color: $tabActiveItemBgColor;
            color: $tabActiveItemColor;
        }
        .k-link{
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}