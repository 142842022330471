@import 'variables.scss';
.app-scrollbar,
html,body,.k-grid-content {
    scrollbar-width: thin;
    scrollbar-color: $scrollColor transparent;
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        background: $scrollColor;
        border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb:hover {
        // background: $app_theme 0.2;
    }
    &::-webkit-scrollbar-track {
        border-radius: 0px;
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none !important;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none !important;  /* IE and Edge */
    scrollbar-width: none !important;  /* Firefox */
  }
