@import "../../assets/styles/variables.scss";

.tab-current-bids{
    .highlight-label{
        .grid-label{
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                color: $planeGridLabelColor;
                word-break: break-word;
        }
       }
       
       .grid-value{
        word-break: break-word;
        color: $titleColor;
       }

       .k-tabstrip-items-wrapper{
        margin-bottom: 8px;
        .k-item.k-active{
            color: black;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $highlightTextColor !important;
            
            }

    }
}