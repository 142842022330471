.app-dropdown {
    border: none;
    min-width: 160px;
    color: $dropdownColor;
    font-size: 16px;
    background-color: $dropdownBgColor !important;

    &:hover{
        border: none;
    }

    .k-input-button,
    .k-input-inner {
        background-color: $dropdownBgColor !important;
    }

}

.tl-view-dropdown{
    width: 200px;
}



.k-picker-solid:focus, .k-picker-solid.k-focus {
    border-color: transparent;
    box-shadow: none;
}
.k-picker-solid:focus-within {
    border-color: transparent;
    box-shadow: none;
}


.k-dropdownlist-popup {
    .k-list-item.k-selected {
        color: $dropdownListSelectedTextColor;
        background-color: $dropdownListSelectedBgColor;
    }

    .k-list-item {
        padding: 8px;

        &:hover {
            color: $dropdownListHoverTextColor;
            background-color: $dropdownListHoverBgColor;
        }

    }
}
.app-multi-select{
    min-height: 40px;
  
}

.full-width-filter{
    z-index: 10003 !important;
    .k-multiselect-popup{
        box-shadow: 0px 2px 4px 0px #00000008;
        border: 1px solid #00000014;
    }
    .k-list-item.k-selected, .k-selected.k-list-optionlabel{
background-color: transparent !important;
color: #424242;

    }
    .k-list-item:focus, .k-list-optionlabel:focus, .k-list-item.k-focus, .k-focus.k-list-optionlabel{
        box-shadow: inset 0 0 0 1px rgba(33, 37, 41, 0.15);
    }
    .k-list-md .k-list-item, .k-list-md .k-list-optionlabel{
        padding: 8px;
    }
    li{
        .k-list-item-text{
            padding-left: 6px;
        }
        input{
            border: 1px solid #00000014;
        }
        input[type="checkbox"] {
            accent-color: #EF6E0B;
            }
    }
}