$primaryColor:#EF6E0B;
$titleColor:#424242;
$secondaryTitleColor:#465166;
$hoursLeftIndicatorColor:#F31700;
$highlightBgColor:#EAF4FF;
$highlightTextColor: #0059A3;
$hrColor: #afb6bd;
$cardHighlightBgColor:#F8F9FA;
$cardLabelColor: #7E8594;
$cardValueColor: #424242;
$borderColor:#DEE2E6;
$layoutPadding:16px;
$blocksPadding:16px;
$spacebetweenRow : 6px;
$appBodyBgColor:#FFFFFF;
//btn colors
$primaryBtnBgColor:#EF6E0B;
$primaryBtnHoverBgColor:#ff6f00;
$primaryBtnHoverTextColor:#FFFFFF;
$primaryBtnHoverBorderColor:#ff6f00;
$secondaryBtnBgColor: #FFFFFF;
$secondaryBtnTextColor: #000000DE;
$secondaryBtnBoxShadowColor: #0000001F;
$errorBtnColor:#FF2E00;
$errorBtnBgColor:#FFE9E9;
$warningBtnColor:#EF6E0B;
$warningBtnBgColor:#FFF;
$warningBtnHoverColor:#EF6E0B;
$shareBtnColor:#EF6E0B;
$linkBtnColor:#ed6e0c;
$linkBtnHoverColor:#f08e42;
$checkBoxSelectedBgColor:#ed6e0c;
$checkBoxSelectedColor:#FFF;
$checkBoxBorderColor:#ed6e0c;
$filterBtnBorderColor:#909294;
$filterBtnHoverBorderColor:#EF6E0B;
$filterBtnIconColor:#424242;
$filterBtnIconHoverColor: #EF6E0B;
$cancelBtnBgColor:#abafb2;
$cancelBtnBorderColor:#abafb2;
$cancelBtnHoverBgColor:#babcbd;
$cancelBtnHoverBorderColor:#babcbd;
$cancelBtnColor:#FFFFFF;
//dropdown colors
 $dropdownColor:#424242;
 $dropdownBorderColor:#909294;
 $dropdownHoverBorderColor:#EF6E0B;
 $dropdownBgColor:#fff;
 $dropdownListSelectedBgColor:#EF6E0B;
 $dropdownListSelectedTextColor:#fff;
 $dropdownListHoverTextColor:#212529;
 $dropdownListHoverBgColor:#ffeee1;
 //grid colors
 $tableGridBorderColor:#E6EEF6;
 $planeGridLabelColor:#465166;
 $appTableBorderColor:grey;
 $planeGridInfoColor:red;
//chip colors
$primaryChipBgColor:#e7f9e6;
$primaryDisabledChipBgColor:#F3F3F3;
$secondaryChipBgColor:#D8E8FF;
$chipLabelColor:#121212;
$unnatiChipBgColor:#EAF4FF;
$unnatiChipBorderColor:#EAF4FF;
$missedCallChipBgColor:#EB5757;
$connectingCallChipBgColor:#F2994A;
$connectedCallChipBgColor:#0D6EFD;
$loyaltyChipBgColor:#6F42C1;
//modal colors
$modalTitleColor:#465166;
//rating colors
$highRatingColor:#0FAB64;
$lowRatingColor:#FF2E00;
$secondaryRatingColor:#0059A3;
//app scrollbar colors
$scrollColor: #c7c7e3;
//tabs
$tabItemColor:#424242;
$tabItemBgColor:#F8F9FA;
$tabItemBorderColor:#EBEBEB;
$tabActiveItemColor:#FFF;
$tabActiveItemBgColor:#EF6E0B;
$tabActiveItemBorderColor:#00000014;
//breadcrumb
$breadcrumbLinkColor:#0D6EFD;
$breadcrumbBgColor:#F8F9FA;
$breadcrumbItemLinkColor:grey;
//tooltip
$callTooltipBgColor:#424242;
$primaryTooltipBgColor:#E7F9E6;
$errorTooltipBgColor:#FFE9E9;
$primaryTooltipTextColor:#121212;

$allCarsTableBgColor:#F8FBFF;
$ongoingCardsBoxShadowColor:#ECF0F4;
//side bar filters
$filterOverlayBgColor:#000;
$filterCloseIconColor:#000;
$filterBgColor:#FFF;
$appExpansionBorderColor:#CED4DA;
$appExpansionBgColor:#F8FBFF;
$appExpansionColor:#424242;
//search input colors
$searchInputBorderColor:#909294;
$searchInputBgColor:#fff;
$searchInputColor:#424242;
$searchIconColor:#EF6E0B;
$searchInputHoverBorderColor:#EF6E0B;

//snackbar colors
$snackbarAlertCaseColor:#E31B54;
$snackbarTextColor:#485164








