.inputFile {
    display: none;
}
  
.customChooseFileButton {
    background-color: #0059A3; 
    color: white;
    padding: 7px 13px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}
  
.customChooseFileButton:hover {
    background-color: #0059A3;
}
  
.fileNameLabel {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #6C757D;
}
  
.customUpload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}

.customLink {
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
}

.uploaded {
    cursor: pointer;
}