@import "variables.scss";
.app-loader-container {
    height: 50px;
    width: 100%;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999;
    pointer-events: none;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right:0;
    .loader {
        /* position: absolute; */
        width: 100%;
      }
      .k-loading-image{
        &::before{
            color: $primaryColor;
        }
      }
  }