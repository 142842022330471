.segmentation-container{
  padding: 20px;

}
.panel-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

}

.panel-name{
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
}


.table-container :global(.gridData){
  margin-top: 20px;
}

.table-container :global(.k-grid-content){
  padding-bottom: 0;
}

.table-container :global(.k-table-md){
  padding-bottom: 0;
  width: 100% !important;

}

.table-container :global(.k-table-row>.k-table-td){
  font-weight: 500;
  word-break: break-word;
}

.table-container :global(.k-pager-md .k-pager-sizes .k-dropdownlist)
{
  width: 80px;
}
