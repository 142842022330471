.singleUploadContainer {
  height: 50vh;
}

.fieldWrapper {
  width: 40vw !important;
  flex: none;
}

.fieldWrapper > :global(.k-label) {
  flex: 0.75;
}

.submitBtn {
  flex: none;
}

.fieldWrapper :global(.k-input) {
  max-width: 301px !important;
}

.actionBtnContainer {
  flex: none !important;
  position: relative !important;
  max-height: fit-content;
  margin-top: 2rem;
}

.confirmModalLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}