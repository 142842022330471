@import "../common/assets/styles/variables.scss";
.all-cars{
    .title-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;


        .title-text {
            font-size: 19px;
            font-weight: 400;
            line-height: 25.27px;
            color: $titleColor;
            white-space: nowrap;
        }

        .tab-strips{

            .k-content{
                display: none;
            }

            .k-item{
                border:none;
                color: #aaa;
             
            }  

            .k-link,.tab-title{
                display: flex;
                justify-content: center;
                font-size: 18px;
                display: flex;
                justify-content: center;

            }

       
            .k-tabstrip-items-wrapper{
                border-bottom: 2px solid $tabItemBorderColor;
            }

            .k-active{
                .k-link{
                    color: $titleColor;
                    font-weight: 600;
                    border-bottom: 2px solid $primaryColor;
                }

                .tab-title{
                    font-weight: 600;
                    font-size: 18px;
                    color: $titleColor;

                }
            }

            .reassign-dealer-count{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                background-color:rgba(180, 39, 46, 1);
                color: white;
                font-weight: 500;
                font-size: 12px;
                margin-left: 8px;
            }
           
        }


       
    }
    .ongoing-car-cards{
        column-gap: 24px;
        box-shadow: 0px 2px 4px 0px $ongoingCardsBoxShadowColor;
        padding: 16px;
        margin-bottom: 24px;
        .car-card-container{
            max-width: 50%;
            width: 50%;
        }
    }

    .pa-name{
        word-break: break-word;
    }

    .pa-email{
        color: $primaryColor;
        font-weight: 500;
        word-break: break-word;
    }
    
}
.total-stocked{
    .average{
       width: 50%;
       text-align: center;
       border-right: 1px solid $tableGridBorderColor;
    }
    .current-month{
        width: 50%;
        text-align: center;
    }
}

