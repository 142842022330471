@import "variables.scss";

.app-share-car-confirm-modal{
    z-index: 999 !important;
    .k-window {
        width: 422px;
        height: 174px;
        border-radius: 4px;
        padding: 16px 32px;
    }
    .k-actions {
        flex-flow: row;
        width: 100%;
        row-gap: 16px;

    }
    .k-window-content{
        padding: 0;
    }
    .share-success-block{
        display: flex;
        flex-flow: column;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: #465166;
        .success-check-icon{
            height: 77px;
        }
        .description{
            padding-top: 14px;
        }
    }

}
.app-share-car-modal {
    z-index: 999 !important;
    // .modal-title {
    //     font-size: 20px !important;
    //     font-weight: 500;
    //     line-height: 28px;
    //     text-align: left;
    //     color: $modalTitleColor;
    //     text-align: center;
    // }

    .k-window {
        width: 444px;
        height: 174px;
        border-radius: 4px;
        padding: 16px 32px;

        .k-window-title {
            font-size: 20px !important;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            color: $modalTitleColor;
            text-align: center;
            padding: 0;
            text-align: left;
        }

        .k-actions {
            flex-flow: row;
            width: 100%;
            row-gap: 16px;
            padding: 20px 0 0 0;

            .k-button {
                width: 100%;
                box-shadow: none;

                .k-button-text {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }

        .k-window-content {
            padding-bottom: 8px;
        }

        .k-window-content {
            padding: 0;
            flex: 0;
        }

        .k-icon-button {
            position: absolute;
            top: 0;
            right: 0;
        }

        .k-window-titlebar {
            text-align: center;
            width: 100%;
            padding: 16px 0;
            white-space: normal;
            display: flex;
    text-align: left;
        }
    }
   

    .shared-cars-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-flow: row;

        .label {
            font-size: 11px;
            font-weight: 400;
            line-height: 11px;
            color: $modalTitleColor;
        }

        .shared-cars-chip-block {
            display: flex;
            column-gap: 12px;

            .highlight-chip {
                background-color: $highlightBgColor;
                color: $highlightTextColor;
                padding: 3px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 14px;
                font-size: 10px;
                border-radius: 4px;
            }
        }

    }

}

.app-confirm-contact-modal {
    .k-window {
        width: 410px;
        height: 150px;
        border-radius: 4px;
        padding: 16px 32px;

        .k-window-title {
            font-size: 20px !important;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            color: $modalTitleColor;
            justify-content: center;
            padding: 0;
        }

        .k-actions {
            flex-flow: column;
            width: 100%;
            row-gap: 16px;
            padding: 6px 0 0;

            .k-button {
                width: 100%;
                box-shadow: none;

                .k-button-text {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }

        .k-window-content {
            padding-bottom: 8px;
        }

        .k-window-content {
            padding: 0;
            flex: 0;
        }

        .k-icon-button {
            position: absolute;
            top: 0;
            right: 0;
        }

        .k-window-titlebar {
            text-align: center;
            width: 100%;
            padding: 16px 0;
        }

        .app-primary-btn {
            color: #fff;
        }

        .modal-title {
            font-size: 16px !important;
            font-weight: 500;
            line-height: 16px;
            text-align: center;
            color: #465166;
        }

        .modal-btn-block {
            width: 100%;
            display: flex;
            justify-content: space-between;
            column-gap: 16px;
        }
    }

}

.app-image-slide-modal{
    .k-dialog-content{
        width: 800px;
        height: 610px;
        overflow: hidden !important;
    }
    .image-slide-container{
       .image-title {
            position: absolute;
            background: rgb(248 147 31 / 10%);
            width: 100%;
            padding: 10px;
            font-size: 16px;
        }
        .slider-video,.slider-img{
            width: calc(100%) !important;
        }
        .k-scrollview-prev, .k-scrollview-next {
            color: $searchInputBorderColor;
            
            &:hover {
                color: $chipLabelColor;
    
            }
        }
    }
    .kendo-scrollview.k-scrollview-wrap, .k-scrollview{
        border-color: #fff;
    }
    .k-scrollview-view{
        border: none;
        box-shadow: none;
        outline: 0;
       
    }
  
}
.app-image-slide-modal{
    z-index: 999 !important;
}