.sortContainer {
  padding: 8px 14px 0;
}

.sortIcon {
  max-width: fit-content !important;
  font-size: 1.5rem !important;
  height: auto;
  cursor: pointer;
}

.activeIcon{
  color: #018dff;
}