.head{
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.head_title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #003E72;
  padding: 0px;
}


.quote_container{
  background: white;
  padding: 0px;
  border-bottom: 1px solid #E6EEF6;
  border-radius:4px;
  color:#465166;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  gap: 20px;
  padding-bottom: 20px;
}

.quote_container_data{
  width: 24% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
}

.quote_container_data--label{
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #9EA7BB;
  margin-bottom: 4px;
}

.quote_container_data--value{
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #465166;
  margin-left: 0px;
}

.last{
  border-bottom: none !important;
}

.quote_container :global(div){
  width: 100%;
}

.quote_container :global(.formData_gridLayout){
  gap:20px !important;
  padding: 0px !important;
}
