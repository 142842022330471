.wrapper {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.listing-header {
    font-size: 22px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    color: #003E72;
}

.assign-button {
    background-color: #0059a3;
    color: #fff;
    width: 140px;
}

.image-text {
    color: #438eff;
    cursor: pointer;
    text-decoration: underline;
}