@import '../../common/assets/styles/variables.scss';

.modal-container{
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 20px;
  border-top: 1px solid #E6EEF6;
  border-bottom: 1px solid #E6EEF6;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  z-index: 2;
  width: 100%;

  .k-input-button{
    background-color: white;
  }

  .k-input-inner{
    background-color: white;
  }
  
  .pa-email-dropdown{
    width: 20%;
    &:hover {
      border: 1px solid $primaryColor;
    }
  }

  .duration-dropdown{
    width: 12%;

    &:hover {
      border: 1px solid $primaryColor;
    }

   
  }

  .date-container{
    position: absolute;
    z-index: 1;
    bottom: 65px;
    right: 180px;

   }

   .reassign-btn{
    background-color: $primaryColor;
    color: white;
   }
   .cross-icon{
    cursor: pointer;
   }
 
}