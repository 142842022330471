
.customCellContainer {
  display: flex;
}

.cellContent {
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
}

.cellTitleContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  width: 140px;
}

.cellTitle{
  font-weight: 600;
}


.cellDescription{
  font-size: 12px;
  color: #424242;
}

.verticalLine {
  width: 1px;
  height: 100%;
  margin: 0 10px;
}

.modalButton{
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  position: sticky;
  bottom: -20px; 
  background-color: white;
  padding: 10px;
  text-align: center;
}

.accept-btn{
  background-color: transparent;
  width: 20%;
  &:hover{
    background-color:green;
    color: white;
  }
}

.reject-btn{
  width: 20%;
  background-color: transparent;
  &:hover{
    background-color:red;
    color: white;
  }
}

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs :global(.k-tabstrip-items .k-link){
  width: 20%;
  border: none;

}
.tabs :global(.k-tabstrip-items-wrapper .k-item.k-active .k-link){
  font-weight: 500;
}

.no-report-found{
  text-align: center;
}

.report-header-container{
  display: flex;
  flex-direction: column;
  width:55%;
  text-align: center;
  padding: 5px;
}

.header-text{
  display: flex;
  justify-content: space-between;
}




