.submitBtn {
  background-color: #003e72;
  color: white;
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
}

.submitBtn:hover {
  background-color: #003e72;
}

.cancelBtn {
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.375rem;
}

.filterContainer {
  padding: 8px 14px;
}