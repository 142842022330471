@import '../../../common/assets/styles/variables.scss';

.app-chips {
    display: flex;
}

.primary-chips {
    .k-chip-solid-base {
        border-color: $primaryChipBgColor;
        color: $chipLabelColor;
        background-color: $primaryChipBgColor;
        padding: 6px;
        border-radius: 100px;
        cursor: default;

    }

    .k-chip-label {
        font-size: 12px;
        line-height: 14px;
    }
}


.primary-chips-disabled {
    .k-chip-solid-base {
        border-color: $primaryDisabledChipBgColor;
        color: $chipLabelColor;
        background-color: $primaryDisabledChipBgColor;
        padding: 6px;
        border-radius: 100px;
        cursor: default;
    }
    .k-chip-label {
        font-size: 12px;
        line-height: 14px;
    }
}

.secondary-chips {
    .k-chip-solid-base {
        border-color: $secondaryChipBgColor;
        color: $chipLabelColor;
        background-color: $secondaryChipBgColor;
        padding: 6px;
        border-radius: 100px;
        font-size: 12px;
        cursor: default;
    }

    .k-chip-label {
        font-size: 12px;
        line-height: 14px;
    }
}


.missed-call-chip{
    .k-chip-solid-base {
        border-color: rgba($missedCallChipBgColor,.1);
        background-color: rgba($missedCallChipBgColor,.1);
        color:$missedCallChipBgColor;
        padding: 6px;
        border-radius: 100px;
        cursor: default;
    }

    .k-chip-label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
    }

}

.connecting-call-chip{
    .k-chip-solid-base {
        border-color: rgba($connectingCallChipBgColor,.1);
        background-color: rgba($connectingCallChipBgColor,.1);
        color: $connectingCallChipBgColor;
        padding: 6px;
        border-radius: 100px;
        cursor: default;
    }

    .k-chip-label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
    }

}

.connected-call-chip{
    .k-chip-solid-base {
        border-color: rgba($connectedCallChipBgColor,.1);
        background-color: rgba($connectedCallChipBgColor,.1);
        color: $connectedCallChipBgColor;
        padding: 6px;
        border-radius: 100px;
        cursor: default;
    }

    .k-chip-label {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
    }

}


.unnati-applicable-chip{
    .k-chip-solid-base {
        color: $chipLabelColor;
        background-color: $unnatiChipBgColor;
        border-color: $unnatiChipBorderColor;
        padding: 4px 4px;
        border-radius: 100px;
        cursor: default;
    }
    .k-chip-label {
        font-size: 10px;
        font-weight: 500;
        color: #007BFFBF;
        line-height: 10px;
    }
}

.loyalty-applicable-chip{
    .k-chip-solid-base {
        color: $chipLabelColor;
        background-color:rgba($loyaltyChipBgColor,0.1);
        border-color:rgba($loyaltyChipBgColor,0.1);
        padding: 4px 4px;
        border-radius: 100px;
        cursor: default;
    }
    .k-chip-label {
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: $loyaltyChipBgColor;
    }
}

.bucket-applicable-chip{
    .k-chip-solid-base {
        color: $chipLabelColor;
        background-color: rgba($connectingCallChipBgColor,0.1);
        border-color:rgba($connectingCallChipBgColor,0.1);
        padding: 4px 4px;
        border-radius: 100px;
        cursor: default;
    }
    .k-chip-label {
        font-size: 10px;
        font-weight: 500;
        color: $connectingCallChipBgColor;
        line-height: 10px;
    }
}