@import "../../assets/styles/variables.scss";

.tab-rfc-details {
    display: flex;
   flex-flow: column;

    .rfc-options {
        min-width: 200px;
        display: flex;
        flex-flow: column;
        background: $tabItemBgColor;
      

        .rfc-option {
            color: $titleColor;
            padding: 8px 16px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            display: flex;

        
        }  
       
        .k-expander{
            border: none;
        }

        .k-expander-header {
            border-top: 1px solid $borderColor;
            height: auto;
          
        }
       
        
        .k-expander-content{
            padding: 0;
        }
        
        .k-focus{
           box-shadow: none;
        }
        
        .active-rfc-option {
            color: $primaryColor;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            
            }

    }


    .rfc-details { 
        width: 100%;
        padding: 16px;
    }


    .engine-video-container{
        margin-top: 16px;
    }

    .plane-grid {
        .label {
            flex: 2;
            display: flex;
            align-items: center;
            gap: 8px;
            .info-icon {
                color: $planeGridInfoColor;
            }
            .label-icon{
               width: 10%;
               max-width: 10%;
               text-align: center;
               display: flex;
               justify-content: center;

               .na-container{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 8px;
                width: 15px;
                height: 15px;
                background-color: $breadcrumbLinkColor;
                color: white;
               }
            }
            .label-text{
                width: 100%;
                word-break: break-word;
            }
        }
        .value{
            flex: 1;
            border-right: 1px solid $tableGridBorderColor;
            word-break: break-word;
            overflow: hidden;
        }
        .image-count{
            padding: 10px;
            margin: auto;
            flex: 1;
            text-align: center;
            position: relative;
            .k-icon{
                font-size: 30px;
            }
            .count{
                position: absolute;
                background: #D9D9D9;
                width: 16px;
                height: 16px;
                font-size: 12px;
                line-height: 16px;
                border-radius: 10px;
                right: -2px;
                bottom: -8px;
            }
        }
        .document-label{
           flex: 1;
        }
        .engine-additional-info{
            display: flex;
            justify-content: center;
            align-items: center;
           .engine-info{
            object-fit: contain;
            width: 100%;
            height: 150px;
           }
        }
    }


    .flag-content-container{
            margin-top: 12px;
            display: flex;
            flex-direction: column;
        
            .flag-content{
                display: flex;
                align-items: center;
                gap: 8px;
                word-break: break-word;

            }
            .flag-text{
                color: $titleColor;
                font-weight: 700;

            }
    }
    

}