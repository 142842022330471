.audioPlayer {
  display: flex;
  align-items: center;
  width: 100%;
}

.audioPlayer :global(.k-slider){
  padding: 0 4px;
  padding-right: 0;
  margin: 0;
}

.audioPlayerBtn {
  border-radius: 100%;
  width: 1.5rem !important;
  height: 1.5rem;
}

.audioPlayerBtn :global(::before) {
  font-size: 10px;
}

.progressBar {
  flex-grow: 1;
}

.durationText {
  font-size: 0.85rem;
  width: 100%;
  min-width: 35px;
  max-width: 50px;
  margin: 0 !important;
}