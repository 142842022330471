.cellTooltipTitle{
    font-size: 14px;
    color: #465166;
    font-weight: 500;
    margin: 5px 0px;
}

.cellTooltipValue{
    font-size: 14px;
    color: #7E8594;
    font-weight: 400;
    margin-bottom: 15px;
    max-width: 180px;
}

.tooltip{
    max-width: 179px;
}

.tooltipTitle{
    font-size: 14px;
    color: #FFFFFF;
    background-color: #003E72;
    font-weight: 400;
    padding: 4px;
}
