.ops-challan-container {
	background-color: #fff;
}

/*
Button
*/
.ops-grid-layout-container .k-button-solid-primary {
	width: 140px;
	background-color: #0059a3;
	color: #fff;
}
.ops-grid-layout-container .k-button-outline-primary {
	width: 140px;
	color: #0059a3;
}
.ops-grid-layout-container .k-button-outline-primary:hover {
	background-color: #ebf5ff;
	color: #0059a3;
	border-color: unset;
}

/*
Table
*/

.ops-grid-layout-container .k-table {
	color: #465166;
}
.ops-grid-layout-container .k-column-title {
	font-size: 14px;
	font-weight: 500;
	color: #003e72;
	align-items: left;
}

.ops-grid-layout-container .k-grid .k-grid-header .k-table-th {
	vertical-align: top;
	justify-content: flex-start;
}

.ops-grid-layout-container .k-grid .k-cell-inner {
	align-items: start;
}

.ops-grid-layout-container .k-grid .k-table-thead {
	background-color: #ebf5ff;
	text-transform: capitalize;
}
.ops-grid-layout-container .k-grid .k-table-alt-row {
	background-color: #f8fcff;
}

.ops-grid-layout-container .k-table .k-table-td {
	text-align: left !important;
}

.ops-grid-layout-container .k-checkbox:checked {
	background-color: #003e72;
}

.ops-grid-layout-container .k-grid-md .k-table-th > .k-cell-inner > .k-link {
	justify-content: start;
}

.k-grid tbody > tr:not(.k-detail-row):hover {
	background-color: #ebf5ff;
}

/*
**Table DropDown**
*/

.ops-grid-layout-container .k-dropdownlist {
	background-color: unset;
	border: unset;
	color: #003e72;
}

/*
**Table Filter**
*/
.kendo-grid-filter-menu-container .k-button-solid-primary {
	background-color: #003e72;
	border-color: unset;
}

.kendo-grid-filter-menu-container .k-button-solid-base {
	border-color: #003e72;
	color: #003e72;
	background-color: unset;
}

.selected-filter {
	color: #ebf5ff;
	background-image: url("../../../../assets/img/filter.svg") !important;
	background-repeat: no-repeat;
	background-position: 100% 10%;
	padding-right: 20px;
	background-origin: content-box;
}

.de-selected-filter {
	color: #003e72;
	background-image: unset !important;
	padding-right: unset !important;
}

.ops-grid-layout-container .k-sort-icon {
	color: #003e72;
}

/* 
.kendo-grid-filter-menu-container .k-svg-icon .k-svg-i-x .k-button-icon {
    background-color: unset;
} */

/*
**DISPOSE STATUS**
*/

/* .ops-grid-layout-container .k-i-circle {
    margin-right: 10px;
} */

.ops-grid-layout-container .status-assigned {
	border: 1px solid #00ba67;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #00ba67;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-unassigned {
	border: 1px solid #eb5757;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #eb5757;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .dispose-agent-assigned {
	border: 1px solid #0d6efd;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #0d6efd;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .dispose-paid {
	border: 1px solid #00ba67;
	border-radius: 100px;
	background-color: #e5fef3;
	font-size: 12px !important;
	color: #00ba67;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .dispose-vc-proceeding {
	border: 1px solid #f6a70a;
	border-radius: 100px;
	background-color: #fff1d6;
	font-size: 12px !important;
	color: #f6a70a;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .unable-fullfil {
	border: 1px solid #eb5757;
	border-radius: 100px;
	background-color: #ffe2e2;
	font-size: 12px !important;
	color: #eb5757;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-raised {
	border: 1px solid #0d6efd;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #0d6efd;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-raise-rejected {
	border: 1px solid #eb5757;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #eb5757;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-amended {
	border: 1px solid #9c45ff;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #9c45ff;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-raise-accepted {
	border: 1px solid #00ba67;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #00ba67;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-pending {
	border: 1px solid #f6a70a;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #f6a70a;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-reopened {
	border: 1px solid #f6a70a;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #f6a70a;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-resolved {
	border: 1px solid #00ba67;
	border-radius: 100px;
	background-color: #ffffff;
	font-size: 12px !important;
	color: #00ba67;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-rejected {
	border: 1px solid #ffa5a5;
	border-radius: 100px;
	background-color: #ffe2e2;
	font-size: 12px !important;
	color: #ffa5a5;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-item-saved {
	border: 1px solid #0d6efd;
	border-radius: 100px;
	background-color: #ffffff;
	font-size: 12px !important;
	color: #0d6efd;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-item-rejected {
	border: 1px solid #eb5757;
	border-radius: 100px;
	background-color: #ffe2e2;
	font-size: 12px !important;
	color: #eb5757;
	padding: 4px 6px;
	line-height: 18px !important;
}

.ops-grid-layout-container .status-item-submitted {
	border: 1px solid #00ba67;
	border-radius: 100px;
	background-color: unset;
	font-size: 12px !important;
	color: #00ba67;
	padding: 4px 6px;
	line-height: 18px !important;
}

/*
**Tab Strip**
*/

.ops-grid-layout-container
	.k-tabstrip-top
	> .k-tabstrip-items-wrapper
	.k-item.k-active {
	color: #003e72 !important;
}

.ops-grid-layout-container .k-tabstrip-items-wrapper .k-item {
	color: #7e8594 !important;
}

/*
**Card Detail**
*/

.ops-grid-layout-container .k-card-header {
	padding-inline: 40px;
}

/*
**Search bar**
*/

.ops-grid-layout-container .search-bar-container {
	display: flex;
	align-items: center;
	align-items: center;
	border: 1px solid #bbc1cd;
	border-radius: 4px;
	margin-bottom: 15px;
}
.ops-grid-layout-container .search-bar-container .k-form-field {
	/* width: 40%; */
	margin-bottom: 0px;
}

.ops-grid-layout-container .search-bar-container .k-combobox {
	border: 0px;
	background-color: #ebf5ff;
	color: #003e72;
	padding: 3px;
	border-radius: 4px 0px 0px 4px;
}

.ops-grid-layout-container .search-bar-container .k-combobox .k-input-button {
	background-color: #ebf5ff;
	border: 0px;
	border-radius: 0px 4px 4px 0px;
}

.ops-grid-layout-container .search-bar-container .k-combobox .k-clear-value {
	display: none;
}

.ops-grid-layout-container .search-bar-container .k-textbox {
	border-radius: 0px 10px 10px 0px;
	border: 0px;
}

/*
**ToolTip**
*/

.k-tooltip {
	background-color: #003e72;
}

/*
**Input**
*/

.k-input-md .k-input-button,
.k-input-md .k-spinner-increase,
.k-input-md .k-spinner-decrease,
.k-picker-md .k-input-button,
.k-picker-md .k-spinner-increase,
.k-picker-md .k-spinner-decrease {
	background-color: #ebf5ff;
}

.k-input-solid:focus-within {
	border-color: none;
	box-shadow: unset;
}

.kendo-grid-filter-menu-container .k-button-solid-base {
	border: 0px;
}

.kendo-grid-filter-menu-container .k-input-md .k-input-button {
	background-color: unset;
}

/*
**Finder Row**
*/

.finder-color-coded-row{
    background-color: #E6EBF8;
}
