.table-as-grid {
    .k-grid {
        border-color: transparent;
        .k-grid-content{
            padding-bottom: 0;
        }
        td {
            border-width: 0px !important;
            border-top: 1px solid $tableGridBorderColor !important;
            border-right: 1px solid $tableGridBorderColor !important;
            padding: 8px 12px;
            vertical-align: top;

            &:last-child {
                // border-right: 0 solid $tableGridBorderColor !important;
            }

            &:first-child {
                border-left: 1px solid $tableGridBorderColor !important;
            }
        }

        .k-table-tbody {
            tr {
                &:hover {
                    background-color: transparent
                }

                &:last-child {
                    td {
                        border-bottom: 1px solid $tableGridBorderColor !important;
                    }
                }
            }

        }
    }
}