.price_modal{
  z-index: 1000 !important;
  padding: 10px;
}

.head{
  display: flex;
  flex-direction: column;
  background-color: white;
  width: fit-content;
  margin: 10px 0px;
  margin-left: 12px;
}

.head_userAction{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.head_userAction--heading{
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #465166;
}

.head_userAction--subheading{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #7E8594;
  margin-bottom: 20px;
}

.head_userActionInput{
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E6EEF6
}

.head_userActionInput--label{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #465166;
}

.head_userActionInput--input{
  width: 357px;
  height: 42px;
  padding: 6px;
  gap: 0px;
  border-radius: 4px;
  border: 1px solid #E6EEF6
}

.head_showDetails{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 10px;
}

.head_showDetails--heading{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0059A3;
}

.head_userButtons{
  display: flex;
  justify-content: space-between;
}

.head_userButtons--submit {
  width: 170.5px;
  height: 42px;
  padding: 9px 13px 9px 13px;
  gap: 2px;
  border-radius: 4px;
  border: 1px solid #005399;
  background: #0059A3;
  color: white;
  margin: 0;
}

.head_userButtons--cancel{
  width: 170.5px;
  height: 42px;
  padding: 9px 13px 9px 13px;
  gap: 2px;
  border-radius: 4px;
  border: 1px solid #005399;
  background: white;
  color: #0059A3;
  margin: 0;
}

.head_showDetailsBreakdown{
  margin: 10px auto;
}

.head_showDetailsBreakdown_details{
  display: flex;
}

.head_showDetailsBreakdown_details--label{
  width: calc(100% - 100px);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.head_showDetailsBreakdown_details--positiveAmount{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 100px;
  color: green;
}

.head_showDetailsBreakdown_details--negativeAmount{
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 100px;
  color: red;
}

.head_showDetailsPriceBreakDown{
  border-bottom: 1px solid #E6EEF6
}

