.sfGrid :global(.k-grid-content) {
  scrollbar-width: none;
}

.sfLeadsTabs :global(.k-item) {
  background: linear-gradient(90deg, #e2e7ec 0%, #ebf0f5 23%);
  border-color: #788791 !important;
  color: #444953 !important;
}

.sfLeadsTabs :global(.k-active) {
  background: white;
  border-color: #788791 !important;
}

.sfGrid :global(.k-pager-sizes > .k-dropdownlist) {
  width: fit-content;
}

.assignedTabsContainer {
  display: relative;
}