.hidden{
  visibility: hidden;
}

.visible{
  visibility: visible;
}

.submitBtn{
	width: fit-content;
	margin-top: 1rem !important;
	margin-left: auto !important;
}

.callHistoryGrid > div {
	height: 200px;
}