.app-breadcumb {
    background: $breadcrumbBgColor;
    padding: 8px;

    .k-breadcrumb-link {
        color: $breadcrumbLinkColor;
        font-size: 16px;
        line-height: 24px;

        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    .k-breadcrumb-root-link {
        padding: 6px;
        margin-right: 4px;
        outline: 0;

        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    .k-breadcrumb-item {
        &:last-child {
            .k-breadcrumb-link {
                color: $breadcrumbItemLinkColor;
                text-decoration: none;
            }
        }
    }
}
