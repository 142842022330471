@import "../../assets/styles/variables.scss";
td {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

table {
  border: 1px solid $appTableBorderColor;
}

.view-all {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: $primaryColor;
  text-align: left;
}
.info-tooltip {
  .k-tooltip {
    background: $titleColor !important;
    width: auto;
    height: auto;
    border-radius: 2px;
  }
}

.company-name-container{
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;

  .activity-status{
    font-size: 12px;
    color: $cardLabelColor;
    word-break: break-word;
    font-weight: 500;
    text-align: left;

    .online-status{
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: $highRatingColor;
      display: inline-block;

    }
  }


  .company-info{
    display: flex;
    width: 100%;
    flex-direction: column;

    .company-container{

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;


      .company-name-body{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;

        .company-name{
            font-size: 16px;
            font-weight: 500;
            color: $highlightTextColor;
            text-align: left;
            word-break: break-word;
            min-width: fit-content;
            max-width: 150px;
          }

        .missed-call-icon-container{
          display: flex;
          align-items: center;
          gap: 6px;
          line-height: 18px;
        }


      }


   } 

  }




}


.app-chips-container {
  max-height: 201px;
  overflow-y: auto;
}

.dealer-info{
  display: flex;
  flex-flow: column;
  text-align: left;
  align-items: flex-start;

  .dealer-type-chip{
    display: flex;
    flex-direction: column;
    gap: 5px;
    column-gap: 8px;
  }

  .dealer-name{
    word-break: break-word;
    margin: 5px 0;
  }
}