.head{
  padding: 10px 20px;
}

.head_header{
  display: flex;
}

.head_grid{
  display: flex;
}