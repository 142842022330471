/* DialPad.css */
.dial-pad {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pin{
  background: rgb(239, 239, 239);
  height: 41px;
  padding: 10px;
  width: 100%;
  margin: 5px;
  color: black;
  font-weight: 700;
  border-radius: 4px;
}


.buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}

.buttons :global(button){
    border: none;
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 50%;
}