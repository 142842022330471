/* ColumnMenuRadioFilter.module.css */

.dropdown {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.radioItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
}

.radioItem:last-child {
    margin-bottom: 0;
}

.radioInput {
    margin-right: 8px;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.clearButton {
    background-color: #f44336;
    color: #fff;
}

.applyButton {
    background-color: #4caf50;
    color: #fff;
}
