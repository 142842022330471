.callModal :global(.k-dialog-content) {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;
}

.pulse {
  height: 100px;
  width: 100px;
  background-color: #0059A3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2rem;
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid #0059A3;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid #0059A3;
  width: calc(100% + 40px);
  height: calc(100% + 40px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s
}

@keyframes pulse {
  0% {
      transform: scale(0.5);
      opacity: 0
  }

  50% {
      transform: scale(1);
      opacity: 1
  }

  100% {
      transform: scale(1.3);
      opacity: 0
  }
}

.callText {
  font-weight: 600;
  font-size: 16px !important;
}