.app-chips {
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 200px;
  overflow: scroll;
}

.app-chips :global(.k-chip-list-md) {
  gap: 6px;
}

.app-chips :global(.k-chip-solid-base) {
  background-color: white;
  padding: 10px;
}

.app-chips :global(.k-chip-solid-base:hover) {
  background-color: #003e72;
  color: white;
}


.chips :global(.k-chip-list-md) {
  gap: 6px;
}
.chips :global(.k-chip-solid-base) {
  background-color: white;
  padding: 10px;
}
.chips :global(.k-chip-solid-base:hover) {
  background-color: #003e72;
  color: white; 
}
